import { Reducer } from "redux";
import {ExaminedPersonStoreType, ExaminedPersonTypes} from "./examinedPersonTypes";
import {saveExaminedPerson} from "./examinedPersonActions"

export type ExaminedPersonState = ExaminedPersonStoreType | null;

const examinedPersonReducer: Reducer<ExaminedPersonState> = (state = null, action) => {
    if (action.type === ExaminedPersonTypes.SAVE_EXAMINED_PERSON) {
        const { payload } = action as ReturnType<typeof saveExaminedPerson>;
        return payload
    }

    return state;
};

export default examinedPersonReducer
