import React, { FC, useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import Content, { ContentType } from "../Content/Content";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { RankQuestionType, ScaleItem } from "./RankQuestion";
import style from "./rankQuestion.module.css";
import { DragDropContext } from "react-beautiful-dnd";
import DropWrapper from "./DropWrapper";
import { required as requiredRule } from "../ErrorMessage/rules";

type DropWrapperType = {
    droppableId: "initColumn" | "rankedColumn";
    index: number;
    editableFields?: boolean;
}

interface RankComponentQuestionProps {
    element: RankQuestionType;
    editableFields?: boolean;
    onChange(answer: number[]): void;
}

const RankQuestionItem: FC<RankComponentQuestionProps> = (
    {
        element: {
            code,
            answer,
            required,
            ordinalNumber,
            content,
            scale,
        },
        editableFields = false,
        onChange,
    }
) => {
    const didMountRef = useRef(false);

    const formContext = useFormContext();
    const form = formContext ? formContext : null;

    const defaultValue = null == answer ? undefined : JSON.parse(answer) as number[];
    const [items, setItems] = useState<ScaleItem[]>(defaultValue ? [] : scale.descriptions)
    const [rankedItems, setRankedItems] = useState<ScaleItem[]>(defaultValue
        ? scale.descriptions.sort((a, b) => defaultValue.indexOf(a.value) - defaultValue.indexOf(b.value))
        : []
    )
    const [value, setValue] = useState<any>([])

    useEffect(() => {
        if (didMountRef.current) {
            const answer = rankedItems.length ? rankedItems.map(item => item && item.value) : []
            setValue(JSON.stringify(answer))
            onChange(answer)
        }
        else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rankedItems])

    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const move = (source: ScaleItem[], destination: ScaleItem[], droppableSource: DropWrapperType, droppableDestination: DropWrapperType) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result: any = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const getList = (id: string) => id === "initColumn" ? items : rankedItems;
    const onDragEnd = (result: any) => {
        const { source, destination } = result
        if (!destination) return

        if (source.droppableId === destination.droppableId) {
            const items: any = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            source.droppableId === 'rankedColumn'
                ? setRankedItems(items)
                : setItems(items)
        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            )

            setItems(result.initColumn)
            setRankedItems(result.rankedColumn)
        }
    }

    return (
        <div className={`${ form && form.errors[code] ? style.invalid : ""} col-12`}>
            <div className={`px-3 py-4 ${style.questionContainer}`}>
                <div className={`font-weight-bold mb-3 ${ style.title }`}>
                    {
                        ordinalNumber &&
                            <span className="d-block" dangerouslySetInnerHTML={{__html: ordinalNumber || ""}}/>
                    }

                    <Content content={content as ContentType} editableFields={editableFields}/>
                </div>

                <div
                  className="row col-12 px-2 flex-column flex-md-row justify-content-around"
                  style={{ pointerEvents: form ? "auto" : "none" }}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <DropWrapper
                            droppableId="initColumn"
                            items={items}
                            editableFields={editableFields}
                        />

                        <DropWrapper
                            droppableId="rankedColumn"
                            items={rankedItems}
                            editableFields={editableFields}
                        />
                    </DragDropContext>

                    <input
                        name={code}
                        ref={form ? form.register(required ? {
                            required: requiredRule,
                            validate: value => JSON.parse(value).length === scale.descriptions.length
                                || "Wszystkie elementy powinny zostać przeniesione do drugiej kolumny"
                        } : {}) : null}
                        type="hidden"
                        value={value || ''}
                        disabled
                    />
                </div>
            </div>

            {
                form
                    ? <div className={style.errorWrapper}>
                        <ErrorMessage name={code}/>
                      </div>
                    : null
            }
        </div>
    );
};

export default RankQuestionItem;
