import React, { FC } from "react";
import { gql } from "apollo-boost";
// import SelectQuestionChildInstruction from "./SelectQuestionChildInstruction";
import SelectQuestionChildImageFemaleInstruction from "./SelectQuestionChildImageFemaleInstruction/SelectQuestionChildImageFemaleInstruction";
import SelectQuestionChildImageMaleInstruction from "./SelectQuestionChildImageMaleInstruction/SelectQuestionChildImageMaleInstruction";
import SelectQuestionCompactInstruction from "./SelectQuestionCompactInstruction";
import SelectQuestionCompactNurseryInstruction from "./SelectQuestionCompactNurseryInstruction";
import SelectQuestionCompactPrenurseryInstruction from "./SelectQuestionCompactPrenurseryInstruction";
import {QuestionnaireElementType} from "../../QuestionnaireElement/QuestionnaireElement";
import {useSelector} from "react-redux";
import {examinedPersonSelector} from "../../../store/examinedPerson/examinedPersonSelectors";

export interface InstructionProps {
    isFemale?: boolean | null;
    isMale?: boolean | null;
    fullName?: string | null;
}

interface SelectQuestionaireInstructionProps {
    element: QuestionnaireElementType;
    editableFields?: boolean;
}

const SelectQuestionaireInstruction: FC<SelectQuestionaireInstructionProps> = ({ element: { selectQuestionInstructionPresentationType }}) => {
    const examinedPerson = useSelector(examinedPersonSelector);
    const isFemale = examinedPerson && examinedPerson.sex && examinedPerson.sex === "FEMALE";
    const isMale = examinedPerson && examinedPerson.sex && examinedPerson.sex === "MALE";
    const fullName = (examinedPerson && examinedPerson.name && examinedPerson.surname)
        ? `${examinedPerson.name} ${examinedPerson.surname}`
        : null;

    const instructionProps = {
        isFemale,
        isMale,
        fullName
    }

    switch (selectQuestionInstructionPresentationType) {
        /*case 'CHILD':
            return <SelectQuestionChildInstruction {...instructionProps}/>*/
        case 'CHILD_IMAGE_FEMALE':
            return <SelectQuestionChildImageFemaleInstruction {...instructionProps}/>
        case 'CHILD_IMAGE_MALE':
            return <SelectQuestionChildImageMaleInstruction {...instructionProps}/>
        case 'COMPACT':
            return <SelectQuestionCompactInstruction {...instructionProps}/>
        case 'COMPACT_NURSERY':
            return <SelectQuestionCompactNurseryInstruction {...instructionProps}/>
        case 'COMPACT_PRENURSERY':
            return <SelectQuestionCompactPrenurseryInstruction {...instructionProps}/>
        default:
            return null
    }
};

export interface SelectQuestionInstructionType {
    id: string;
    selectQuestionInstructionPresentationType: 'CHILD'
        | 'CHILD_IMAGE_FEMALE'
        | 'CHILD_IMAGE_MALE'
        | 'COMPACT'
        | 'COLUMN'
        | 'COMPACT_NURSERY'
        | 'COMPACT_PRENURSERY'
        | 'CHILD_STRUCTURED';
}

export const SELECT_QUESTION_INSTRUCTION_FRAGMENT = gql`
    fragment SelectQuestionInstruction on SelectQuestionInstruction {
        id
        selectQuestionInstructionPresentationType: presentationType
    }
`

export default SelectQuestionaireInstruction;
