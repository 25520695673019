import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserHistory } from "history";
import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from "react-hot-loader";
import { compose } from "redux";
import client from "./client";
import withErrorScreen from "./components/ErrorScreen/withErrorScreen";
import Router from "./components/Router/Router";
import withApollo from "./hoc/withApollo";
import withConnectedRouter from "./hoc/withConnectedRouter";
import withHelmet from "./hoc/withHelmet";
import withOfflineMask from "./hoc/withOfflineMask/withOfflineMask";
import withReduxProvider from "./hoc/withReduxProvider";
import withRouterProvider from "./hoc/withRouterProvider";
import withSupportedBrowser from "./hoc/withSupportedBrowser";
import withTenantContext from "./hoc/withTenantContext";
import * as serviceWorker from './serviceWorker';
import createStore from "./store/createStore";
import preloadState from "./store/preloadState";
import { updateVersion } from "./store/version/versionActions";

const debug = process.env.NODE_ENV !== "production";
const history = createBrowserHistory();
const preloadedState = preloadState();
export const store = createStore(history, debug, preloadedState);

const renderer: (c: ComponentType) => ComponentType = compose(
    withSupportedBrowser,
    withOfflineMask,
    withHelmet,
    withReduxProvider(store),
    withErrorScreen,
    withRouterProvider(history),
    withConnectedRouter(history),
    withApollo(client),
    withTenantContext,
);

const render = () => {
    const Main = renderer(Router);

    ReactDOM.render((
        <AppContainer>
            <Main />
        </AppContainer>
    ), document.getElementById("root"));
};

if ((module as any).hot) {
    (module as any).hot.accept(`./components/Router/Router`, render);
}

render();

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: () => store.dispatch(updateVersion()),
});
