import { gql } from "apollo-boost";
import React, { FC } from "react";
import { ClassLevel, classLevelLabel } from "../RegistrationPage/FormItems/EnumTypes/ClassLevel";
import Sex from "../Sex/Sex";
import ProblemLevel from "./ProblemLevel";

interface ExaminedPersonDataProps {
    person: ExaminedPersonDataType["person"];
}

const ExaminedPersonData: FC<ExaminedPersonDataProps> = ({person}) => {
    return (
        <dl className="row">
            {person?.identifier ? (
                <>
                    <dt className="col-12 col-md-6">
                        Identyfikator osoby badanej:
                    </dt>
                    <dd className="col-12 col-md-6">
                        {person.identifier}
                    </dd>
                </>
            ) : null}
            {person?.birthDate ? (
                <>
                    <dt className="col-12 col-md-6">
                        Data urodzenia
                    </dt>
                    <dd className="col-12 col-md-6">
                        {person.birthDate}
                    </dd>
                </>
            ) : null}
            {person?.sex ? (
                <>
                    <dt className="col-12 col-md-6">
                        Płeć:
                    </dt>
                    <dd className="col-12 col-md-6">
                        <Sex value={person.sex}/>
                    </dd>
                </>
            ) : null}
            {person?.classLevel ? (
                <>
                    <dt className="col-12 col-md-6">
                        Poziom klasy:
                    </dt>
                    <dd className="col-12 col-md-6">
                        {classLevelLabel[person.classLevel]}
                    </dd>
                </>
            ) : null}
            {person?.diaryNumber ? (
                <>
                    <dt className="col-12 col-md-6">
                        Nr w dzienniku:
                    </dt>
                    <dd className="col-12 col-md-6">
                        {person.diaryNumber}
                    </dd>
                </>
            ) : null}
            {person?.problemLevel ? (
                <>
                    <dt className="col-12 col-md-6">
                        Stopień problemu:
                    </dt>
                    <dd className="col-12 col-md-6">
                        <ProblemLevel problemLevel={person.problemLevel}/>
                    </dd>
                </>
                ) : null}
            { person?.disabilityStatement ? (
                <>
                    <dt className="col-12 col-md-6">
                        Orzeczenie niepełnosprawności:
                    </dt>
                    <dd className="col-12 col-md-6">
                        { person.disabilityStatement }
                    </dd>
                </>
            ) : null}
        </dl>
    );
};

export interface ExaminedPersonDataType {
    __typename: "ExaminedPersonData";
    id: string;
    person?: {
        id: string;
        birthDate: string;
        sex: "MALE" | "FEMALE";
        identifier: string;
        disabilityStatement?: string;
        problemLevel?: "PROBLEMATIC" | "NORMAL" | "UNPROBLEMATIC";
        classLevel?: ClassLevel;
        diaryNumber?: string;
    }
}

export const EXAMINED_PERSON_DATA_FRAGMENT = gql`
    fragment ExaminedPersonData on ExaminedPersonData {
        id
        person(examinationId: $examinationId) @skip(if: $withoutExamination){
            id
            birthDate
            sex
            identifier
            problemLevel
            disabilityStatement
            classLevel
            diaryNumber
        }
    }
`;

export default ExaminedPersonData;
