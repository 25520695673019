import { Option } from "../SelectComponent/SelectComponent";

export enum ClassLevel {
    PRENURSERY = 'PRENURSERY',
    NURSERY = 'NURSERY',
    KINDERGARTEN = 'KINDERGARTEN',
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE',
    FOUR = 'FOUR',
    FIVE = 'FIVE',
    SIX = 'SIX',
    SEVEN = 'SEVEN',
    EIGHT = 'EIGHT',
    HIGH_SCHOOL = 'HIGH_SCHOOL',
    COLLEGE = 'COLLEGE',
}

export const CHILD_CLASSES = [
    ClassLevel.ONE,
    ClassLevel.TWO,
    ClassLevel.THREE,
]

export const TEENAGE_YOUNG_CLASSES = [
    ClassLevel.FOUR,
    ClassLevel.FIVE,
    ClassLevel.SIX,
]

export const TEENAGE_CLASSES = [
    ClassLevel.SEVEN,
    ClassLevel.EIGHT,
]

export const EARLY_LEANING_CLASSES = [
    ClassLevel.PRENURSERY,
    ClassLevel.NURSERY,
    ClassLevel.KINDERGARTEN,
    ...CHILD_CLASSES
];

export const IMMATURE_CLASSES = [
    ...EARLY_LEANING_CLASSES,
    ...TEENAGE_YOUNG_CLASSES,
    ...TEENAGE_CLASSES
];

export const ANY_MATURE_CLASSES = [
    ClassLevel.HIGH_SCHOOL,
];

export const MATURE_CLASSES = [
    ClassLevel.COLLEGE,
];

export const CLASS_LEVELS = [
    ...IMMATURE_CLASSES,
    ...ANY_MATURE_CLASSES,
    ...MATURE_CLASSES
];

export const classLevelLabel = {
    PRENURSERY: "Dziecko 0-1 lat",
    NURSERY: "Dziecko 1-3 lat",
    KINDERGARTEN: "Dziecko 4-6 lat",
    ONE: "1",
    TWO: "2",
    THREE: "3",
    FOUR: "4",
    FIVE: "5",
    SIX: "6",
    SEVEN: "7",
    EIGHT: "8",
    HIGH_SCHOOL: "Szkoła ponadpodstawowa",
    COLLEGE: "Studia"
}

export const classLevelOptions = CLASS_LEVELS.map((item: ClassLevel) => {
    return {
        value: item,
        label: classLevelLabel[item]
    }
})

const availableOptionsForSelectedCategory = (category: string) => {
    switch (category) {
        case "PRENURSERY":
            return [ClassLevel.PRENURSERY];
        case "NURSERY":
            return [ClassLevel.NURSERY];
        case "KINDERGARTEN":
            return [ClassLevel.KINDERGARTEN];
        case "CHILD":
            return CHILD_CLASSES;
        case "TEENAGER_YOUNG":
            return TEENAGE_YOUNG_CLASSES;
        case "TEENAGER":
            return TEENAGE_CLASSES;
        case "HIGH_SCHOOL":
            return [ClassLevel.HIGH_SCHOOL];
        case "COLLEGE":
            return [ClassLevel.COLLEGE];
        default:
            return CLASS_LEVELS
    }
}

export const getAvailableOptionsForSelectedCategory: ((category: string, getBy: "value" | "label") => Array<string>) =
    (
        category,
        getBy = "label"
    ) => {
        return availableOptionsForSelectedCategory(category)
            .map((item: ClassLevel) => {
                return {
                    value: item,
                    label: classLevelLabel[item]
                }
            })
            .map((item: Option) => item[getBy])
    }
