import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { forwardRef, RefForwardingComponent, useEffect, useRef, useState } from "react";
import * as Draft from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import style from "./editor.module.css";

interface EditorProps {
    initialValue?: string;
    value?: string;
    placeholder?: string;
    name?: string;
    onChange?: (value: string) => void;
}

const Editor: RefForwardingComponent<HTMLTextAreaElement, EditorProps> = (
    {
        initialValue,
        value,
        placeholder,
        onChange,
        name
    },
    ref
) => {
    const [html, setHtml] = useState<string>(value || initialValue || '');
    const [editorState, setEditorState] = useState<Draft.EditorState>(fromHtml(html));
    const timeout = useRef<null | number>(null);

    useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => setHtml(toHtml(editorState)), 500) as any;

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }
    }, [setHtml, editorState]);

    useEffect(() => {
        if (onChange) {
            onChange(html);
        }
    }, [onChange, html]);

    const editor = (
        <Draft.Editor
            placeholder={placeholder}
            editorState={editorState}
            toolbarClassName={style.toolbar}
            editorClassName={style.editor}
            toolbar={toolbar}
            onEditorStateChange={setEditorState}
        />
    );

    const textarea = (
        <textarea
            ref={ref}
            className={style.textarea}
            value={html}
            onChange={noop}
            name={name}
        />
    );

    return (
        <>
            {editor}
            {textarea}
        </>
    );
}

const toolbar = {
    options: ['inline', 'history'],
    inline: {
        options: ['bold', 'italic']
    },
};

const toHtml = (state: Draft.EditorState): string => draftToHtml(convertToRaw(state.getCurrentContent()));

const fromHtml = (html: string): Draft.EditorState => {
    const initialContent = htmlToDraft(html)
    const contentState = ContentState.createFromBlockArray(initialContent.contentBlocks);
    return EditorState.createWithContent(contentState);
};

const noop = () => null;

export default forwardRef(Editor);
