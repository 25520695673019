import {SpecialEducationalNeedsType} from "../../components/ExaminedPersonData/SpecialEducationalNeeds"

export enum ExaminedPersonTypes {
    SAVE_EXAMINED_PERSON = "examinedPerson/saveExaminedPerson",
}

export type ExaminedPersonStoreType = ExaminedPersonType

export type ExaminedPersonType = {
    id: string;
    problemLevel?: 'PROBLEMATIC' | 'NORMAL' | 'UNPROBLEMATIC';
    specialEducationalNeeds: SpecialEducationalNeedsType[];
    sex: "MALE" | "FEMALE" | null;
    name?: string;
    surname?: string;
}
