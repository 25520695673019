import { gql } from "apollo-boost";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { examinationAnswer } from "../../store/pendingExaminationAnswers/pendingExaminationAnswersActions";
import { CONTENT_FRAGMENT, ContentType } from "../Content/Content";
import { QuestionnaireElementType } from "../QuestionnaireElement/QuestionnaireElement";
import SelectChildQuestion from "./SelectChildQuestion/SelectChildQuestion"
import SelectColumnQuestion from "./SelectColumnQuestion/SelectColumnQuestion"
import SelectCompactQuestion from "./SelectCompactQuestion/SelectCompactQuestion"
import SelectChildStructuredQuestionQuestion from "./SelectChildStructuredQuestion/SelectChildStructuredQuestion"

interface SelectQuestionProps {
    element: QuestionnaireElementType;
    editableFields?: boolean;
}

export interface SelectComponentQuestionProps {
    element: SelectQuestionType;
    onChange(value: any): void;
    editableFields?: boolean;
}

const SelectQuestion: FC<SelectQuestionProps> = (props) => {
    const dispatch = useDispatch()
    const formContext = useFormContext();
    const getValues = formContext ? formContext.getValues : null;
    const { code } = props.element

    const onChange = async (value: number) => {
        const answer = props.element.multi
            ? getValues!(props.element.code).map((value: string) => parseInt(value))
            : value

        dispatch(examinationAnswer({
            questionCode: code,
            answer
        }))
    }

    const selectQuestionComponents: {[key: string]: FC<SelectComponentQuestionProps>} = {
        "CHILD": SelectChildQuestion,
        "COMPACT": SelectCompactQuestion,
        "COLUMN": SelectColumnQuestion,
        "CHILD_STRUCTURED": SelectChildStructuredQuestionQuestion,
    };
    const SelectQuestionComponent = selectQuestionComponents[props.element.selectQuestionPresentationType] || null

    return (
        <SelectQuestionComponent
            element={props.element}
            onChange={onChange}
            editableFields={props.editableFields}
        />
    )
};

export interface ScaleItem {
    id: string;
    value: number;
    rangeFrom: number;
    rangeTo: number;
    content: ContentType;
    description?: ContentType;
}

export interface SelectQuestionType {
    id: string;
    selectQuestionPresentationType: 'CHILD' | 'COMPACT' | 'COLUMN' | 'CHILD_STRUCTURED';
    content: ContentType;
    ordinalNumber: string;
    code: string;
    required: boolean;
    multi: boolean;
    tabIndex: number;
    answer?: string;
    scale: {
        id: string;
        question: ContentType | null;
        descriptions: Array<ScaleItem>
    };
}

export const SELECT_QUESTION_FRAGMENT = gql`
    fragment SelectQuestion on SelectQuestion {
        id
        selectQuestionPresentationType: presentationType
        ordinalNumber
        code
        content {
            id
            ...Content
        }
        answer(examinationId: $examinationId) @skip(if: $withoutExamination)
        required
        multi
        scale {
            id
            question {
                id
                ...Content
            }
            descriptions {
                id
                rangeFrom
                value
                rangeTo
                content {
                    id
                    ...Content
                }
                description {
                    id
                    ...Content
                }
            }
        }
    }
    ${CONTENT_FRAGMENT}
`;

export default SelectQuestion;
