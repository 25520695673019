import { gql } from "apollo-boost";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { examinationAnswer } from "../../store/pendingExaminationAnswers/pendingExaminationAnswersActions";
import { CONTENT_FRAGMENT, ContentType } from "../Content/Content";
import { QuestionnaireElementType } from "../QuestionnaireElement/QuestionnaireElement";
import RankQuestionItem from "./RankQuestionItem";

interface RankQuestionProps {
    element: QuestionnaireElementType;
    editableFields?: boolean;
}

const RankQuestion: FC<RankQuestionProps> = (props) => {
    const dispatch = useDispatch()
    const { code } = props.element

    const onChange = async (answer: number[]) => {
        if (answer.length === props.element.scale.descriptions.length) {
            dispatch(examinationAnswer({
                questionCode: code,
                answer
            }))
        }
    }

    return (
        <RankQuestionItem
            element={props.element}
            editableFields={props.editableFields}
            onChange={onChange}
        />
    )
};

export interface ScaleItem {
    id: string;
    value: number;
    content: ContentType;
    description?: ContentType;
}

export interface RankQuestionType {
    id: string;
    content: ContentType;
    ordinalNumber?: string;
    code: string;
    required: boolean;
    answer?: string;
    scale: {
        id: string;
        question: ContentType | null;
        descriptions: Array<ScaleItem>
    };
}

export const RANK_QUESTION_FRAGMENT = gql`
    fragment RankQuestion on RankQuestion {
        id
        ordinalNumber
        code
        content {
            id
            ...Content
        }
        answer(examinationId: $examinationId) @skip(if: $withoutExamination)
        required
        scale {
            id
            question {
                id
                ...Content
            }
            descriptions {
                id
                value
                content {
                    id
                    ...Content
                }
                description {
                    id
                    ...Content
                }
            }
        }
    }
    ${CONTENT_FRAGMENT}
`;

export default RankQuestion;
