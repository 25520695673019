import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { ComponentType } from "react";
import LoadingPage from "../components/LoadingScreen/LoadingPage";

const TENANT_CONTEXT_QUERY = gql`
    query TenantContext {
        context {
            pilot
        }
    }
`;

interface Context {
    pilot: boolean;
}

function withTenantContext<Props>(Wrapped: ComponentType<Props>): ComponentType<Props> {
    const Component = (props: Props) => {
        const {data, error, loading} = useQuery<{context?: Context}>(TENANT_CONTEXT_QUERY);

        if (loading) return <LoadingPage />;
        if (error) throw error;

        return <Wrapped {...props} context={data!.context} />;
    };

    Component.displayName = `withTenantContext(${Wrapped.displayName || Wrapped.name})`;

    return Component;
}

export default withTenantContext;
