import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Content, { ContentType } from "../../../Content/Content";
import { required } from "../../../ErrorMessage/rules";
import LikertScaleButton from "../../LikertScaleButton/LikertScaleButton";
import { ItemProps } from "../LikertScaleItem";
import style from "./likertScaleImageItem.module.css"
import {useSelector} from "react-redux";
import {wcagSelector} from "../../../../store/wcag/wcagSelectors";
import {WcagState} from "../../../../store/wcag/wcagReducer";

const LikertScaleImageItem: FC<ItemProps> = (
    {
        name,
        scale,
        ordinalNumber,
        defaultValue,
        nthOfType,
        onChange,
        children,
        required: isRequired,
        editableFields,
        content
    }
) => {
    const form = useFormContext();
    const highContrast = useSelector(wcagSelector) === WcagState.CONTRAST

    const getContentItemByType = (type: string) => {
        if (!content || content.__typename !== 'ContentList') return null
        return content.elements!.find((contentEl: ContentType) => contentEl.__typename === type)
    }

    const buttons = scale.descriptions.map((description, idx) => {
        const formValue = form && form.watch(name)
        const value = `${description.value}`;

        const enableFocus = (form && !form.getValues(name) && !defaultValue && !idx) // focus on first button if is none selected [WCAG]
            || defaultValue === value || (formValue && formValue === value) // focus on already selected field [WCAG]

        return (
            <div
                className="text-md-center col d-md-flex flex-md-column align-items-center my-md-2 mx-1 mb-1"
                key={description.id}
            >
                <LikertScaleButton
                    disabled={!form}
                    defaultChecked={defaultValue === value}
                    value={value}
                    name={name}
                    onChange={onChange}
                    tabIndex={form && enableFocus ? 0: -1}
                    ref={form ? form.register(isRequired ? {required} : {}) : null}
                >
                    <Content content={description.content}/>
                </LikertScaleButton>

                <span className="d-md-none" >
                    {' – '}
                </span>

                <span className={ style.description }>
                    <Content
                        editableFields={editableFields}
                        content={{
                            __typename: "TextContent",
                            id: `${(description.description as any).text}`,
                            text: (description.description as any).text,
                            englishText: (description.description as any).englishText,
                            ukrainianText: (description.description as any).ukrainianText,
                            russianText: (description.description as any).russianText,
                        }}
                    />
                </span>
            </div>
        )
    })

    const even = nthOfType % 2;

    return (
        <div className={`
            col-12 py-3 py-md-2 border-bottom
            ${ordinalNumber === '1.' ? "border-top" : ""}
            ${even ? "bg-light" : "bg-white"}
        `}>
            <div className="row">
                { !highContrast && (
                    <div className="col-12 col-md-4">
                        <Content
                            content={getContentItemByType("ImageContent") as ContentType}
                            editableFields={editableFields}
                        />
                    </div>
                )}

                <div className={`col-12 d-flex flex-column ${highContrast ? "" : "col-md-8"}`}>
                    <div className="mt-2">
                        <span
                            dangerouslySetInnerHTML={{__html: ordinalNumber}}
                            className="mr-1"
                        />
                        <Content
                            content={getContentItemByType("TextContent") as ContentType}
                            editableFields={editableFields}
                        />
                    </div>

                    <div className="row flex-grow-1 align-content-center flex-column flex-md-row justify-content-start justify-content-md-between no-gutters my-3">
                        { buttons }
                    </div>
                </div>
            </div>

            {/* error */}
            <div>{children}</div>
            {/* e/o error */}
        </div>
    )
}

export default LikertScaleImageItem;
