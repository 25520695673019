import { gql } from "apollo-boost";
import React, { FC } from "react";
import Content, { CONTENT_FRAGMENT, ContentType } from "../../Content/Content";
import { getLikertCols } from "../LikertPresentationTypesItems/LikertScaleItem";
import LikertScaleLegend from "../LikertScaleLegend/LikertScaleLegend";
import styles from "./likertScaleQuestion.module.css"

interface LikertScaleQuestionProps {
    element: LikertScaleQuestionType;
    editableFields: boolean;
}

const LikertScaleQuestion: FC<LikertScaleQuestionProps> = ({element: {scale, presentationType, legendType}, editableFields}) => {
    const isCompactType = presentationType === "COMPACT"
    const isSkewLegend = legendType === "DIAGONAL"
    const isLegendVisible = legendType !== "HIDDEN"

    const items = scale.descriptions.map((item) => (
        <div key={item.id} className={`${styles.wrapper} col`}>
            {item.description ? (<>
                <span className={`${styles.span} ${isSkewLegend ? styles.spanSkew : null} text-center small`}>
                     <Content content={item.description} editableFields={editableFields}/>
                </span>

                <div className={styles.line}/>
            </>)
            : null}
        </div>
    ));

    const cols = getLikertCols();

    const classicMiniLegend = (
        <div className={cols.options}>
            <div className={`${styles.row} d-none d-md-flex row justify-content-between ml-0`}>
                { items }
            </div>

            <div className="d-block d-md-none mt-3">
                <LikertScaleLegend scale={scale} editableFields={editableFields}/>
            </div>
        </div>
    );

    const compactMiniLegend = (
        <div>
            <div className="d-none d-md-block row p-3">
                <LikertScaleLegend scale={scale} editableFields={editableFields} miniLegend/>
            </div>

            <div className="d-block d-md-none row p-3">
                <LikertScaleLegend scale={scale} editableFields={editableFields}/>
            </div>
        </div>
    );

    return isLegendVisible
        ? <div className={`border-top border-bottom mt-3 col-12 ${styles.likertScaleQuestionWrapper} position-sticky bg-white`}>
            { isCompactType ? <>{ compactMiniLegend }</> : null }

            <div className="row pb-3 pt-3 justify-content-between no-gutters">
                <div className={`${isCompactType ? "col-12" : cols.question} font-weight-bold align-self-end`}>
                    { scale.question
                      ? <Content content={scale.question} editableFields={editableFields}/>
                      : null
                    }
                </div>

                { !isCompactType ? <>{ classicMiniLegend }</> : null }
            </div>
        </div>
        : <div style={{ height: '30px', width: '100%' }}/>
};

export interface LikertScaleQuestionType {
    id: string;
    presentationType: string;
    legendType: "HORIZONTAL" | "DIAGONAL" | "HIDDEN";
    scale: {
        id: string;
        question: ContentType | null;
        descriptions: Array<{
            id: string;
            value: number;
            content: ContentType;
            description?: ContentType;
        }>
    };
}

export const LIKERT_SCALE_QUESTION_FRAGMENT = gql`
    fragment LikertScaleQuestion on LikertScaleItem {
        id
        presentationType
        legendType
        scale {
            id
            question {
                id
                ...Content
            }
            descriptions {
                id
                value
                content {
                    id
                    ...Content
                }
                description {
                    id
                    ...Content
                }
            }
        }
    }
    ${CONTENT_FRAGMENT}
`;

export default LikertScaleQuestion;
