import { Reducer } from "redux";
import { saveExaminationId } from "./examinationIdActions";
import { ExaminationIdTypes } from "./examinationIdTypes";

export type ExaminationIdState = string | null;

const examinationIdReducer: Reducer<ExaminationIdState> = (state = null, action) => {
    if (action.type === ExaminationIdTypes.SAVE_EXAMINATION_ID) {
        const { payload } = action as ReturnType<typeof saveExaminationId>;
        return payload
    }

    return state;
};

export default examinationIdReducer
