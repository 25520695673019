import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import {ErrorMessage as Message} from "@hookform/error-message";

interface ErrorMessageProps {
    name: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({name}) => {
    const form = useFormContext();

    if (!form) {
        return null;
    }

    return (
        <Message
            name={name}
            errors={form.formState.errors}
            render={({ message }) => (
                <div className="text-danger">
                    {message}
                </div>
            )}
        />
    );
};

export default ErrorMessage;
