import { Reducer } from "redux";
import { examinationAnswer, examinationAnswerSaved } from "./pendingExaminationAnswersActions";
import { PendingExaminationAnswersTypes } from "./pendingExaminationAnswersTypes";

export type PendingExaminationAnswers = {
    [key: string]: any;
};

export type AnswerItemType = {
    questionCode: string,
    answer: any,
}

const pendingExaminationAnswersReducer: Reducer<PendingExaminationAnswers> = (
    state: PendingExaminationAnswers = {},
    action
) => {
    switch (action.type) {
        case PendingExaminationAnswersTypes.EXAMINATION_ANSWER: {
            const { payload: { questionCode, answer }} = action as ReturnType<typeof examinationAnswer>
            return {
                ...state,
                [questionCode]: answer,
            }
        }
        case PendingExaminationAnswersTypes.EXAMINATION_ANSWER_SAVED: {
            const { payload } = action as ReturnType<typeof examinationAnswerSaved>
            const newState = {...state}

            for (let [savedQuestionCode, savedAnswer] of Object.entries(payload)) {
                if (newState[savedQuestionCode] === savedAnswer) {
                    delete newState[savedQuestionCode]
                }
            }

            return newState
        }
        default:
            return state
    }
};

export default pendingExaminationAnswersReducer
