import React, { FC } from 'react'
import { Droppable, Draggable } from "react-beautiful-dnd";
import Content, { ContentType } from "../Content/Content";
import style from "./rankQuestion.module.css"
import { ScaleItem } from "./RankQuestion";

interface DropWrapperProps {
    droppableId: string;
    items: ScaleItem[];
    editableFields?: boolean;
}

const DropWrapper: FC<DropWrapperProps> = ({ droppableId, items, editableFields = false }) => {
    return (
        <Droppable droppableId={droppableId}>
            { (provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    className={`col-12 col-lg-6 py-2 ${ snapshot.isDraggingOver ? style.draggingOverwrapper : style.wrapper }`}
                    style={{ border: "5px solid white", borderRadius: "8px", minHeight: "60px"}}
                >
                    { items.map((item: any, index: number) => (
                        <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                        >
                            { (provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`
                                        ${ style.button }
                                        card bg-light p-2 my-1 border border-dark
                                        ${ snapshot.isDragging ? style.buttonDragging : "" }
                                    `}
                                >
                                    <Content
                                      content={item.content as ContentType}
                                      editableFields={editableFields}
                                    />
                                </div>
                            )}
                        </Draggable>
                    ))}

                    { provided.placeholder }
                </div>
            )}
        </Droppable>
    )
}

export default DropWrapper