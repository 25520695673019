import { Reducer } from "react";
import { Action } from "redux";
import { setWcag } from "./wcagActions";
import { WcagTypes } from "./wcagTypes";

export enum WcagState {
    NONE,
    CONTRAST,
}

export const wcagReducer: Reducer<WcagState, Action<WcagTypes>> = (state = WcagState.NONE, action) => {

    if (action.type === WcagTypes.SET) {
        const {payload} = action as ReturnType<typeof setWcag>

        return payload
    }

    return state
}
