import React, {FC} from 'react'
import BlankLayout from "../../layouts/BlankLayout/BlankLayout";
import {RouteComponentProps} from "react-router";
import Survey from "./Survey";

interface SurveyPreviewPageParams {
    surveyId: string;
}

const SurveyPreviewPage: FC<RouteComponentProps<SurveyPreviewPageParams>> = ({match: {params}}) => {
    return (
        <BlankLayout title="Podgląd ankiety">
            <Survey surveyId={params.surveyId} preview/>
        </BlankLayout>
    )
}

export default SurveyPreviewPage