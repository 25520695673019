import { gql } from "apollo-boost";
import React, { FC } from "react";
import Content, { CONTENT_FRAGMENT, ContentType } from "../../Content/Content";
import style from "./likertScaleHeader.module.css"
import LikertScaleLegend, {LIKERT_SCALE_LEGEND_FRAGMENT, LikertScaleLegendType} from "../LikertScaleLegend/LikertScaleLegend";
import {useSelector} from "react-redux";
import {examinationNameSelector} from "../../../store/examinationName/examinationNameSelectors";
import {langSelector} from "../../../store/lang/langSelectors";
import {LangState} from "../../../store/lang/langReducer";

export interface LikertScaleHeaderProps {
    element: LikertScaleHeaderType;
    editableFields?: boolean;
}

const LikertScaleHeader: FC<LikertScaleHeaderProps> = ({element, editableFields}) => {
    const examinationToolName = useSelector(examinationNameSelector);
    const imagesLikertScaleHeaderTools = [
        "Kwestionariusz 10-12/O-m/S",
        "Kwestionariusz 10-12/O-m/S/U",
        "Kwestionariusz 10-12/O-f/S",
        "Kwestionariusz 10-12/O-f/S/U",
        "Kwestionariusz 10-12/KS-m/S",
        "Kwestionariusz 10-12/KS-m/S/U",
        "Kwestionariusz 10-12/KS-f/S",
        "Kwestionariusz 10-12/KS-f/S/U",
        "Kwestionariusz 10-12/KM-m/S",
        "Kwestionariusz 10-12/KM-m/S/U",
        "Kwestionariusz 10-12/KM-f/S",
        "Kwestionariusz 10-12/KM-f/S/U",
        "Kwestionariusz 7-9/O-f/S",
        "Kwestionariusz 7-9/O-f/S/U",
        "Kwestionariusz 7-9/O-m/S",
        "Kwestionariusz 7-9/O-m/S/U",
    ]

    const isImagesLikertScaleHeaderTool = examinationToolName && imagesLikertScaleHeaderTools.includes(examinationToolName)
    
    const currentLang = useSelector(langSelector)
    let textDescription = 'Odpowiedzi należy udzielać, klikając w odpowiedni przycisk, posługując się następującą skalą:'
    if (currentLang === LangState.RUS) textDescription = "Отвечать следует, нажимая на соответствующую кнопку и используя следующую шкалу:"
    if (currentLang === LangState.UK) textDescription = "Відповідати слід, натискаючи на відповідну кнопку i використовуючи наступну шкалу:"

    return (
        <div
            className={`
                ${style.wrapper}
                ${element.scale ? style.pageBreakAfter : ""}
                mb-0 mt-2 pt-4 pb-4 col-12 jumbotron
             `}
            style={{ backgroundColor: element.content.__typename === "TextContent" && !element.content.text ? "#fff" : "" }}
        >
            <div className="row">
                <div className={"col-sm-12"}>
                    <p>
                        <Content content={element.content}/>
                    </p>
                </div>

                { (element.scale && !isImagesLikertScaleHeaderTool) && (
                    <div className="col-sm-12">
                        <p>
                            {textDescription}
                        </p>

                        <div className={"d-flex align-items-center justify-content-center"}>
                            <LikertScaleLegend scale={element.scale} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};

export const LIKERT_SCALE_HEADER_FRAGMENT = gql`
    fragment LikertScaleHeader on LikertScaleHeader {
        id
        content {
            id
            ...Content
        }
        scale {
            id
            ...LikertScaleLegend
        }
    }
    ${CONTENT_FRAGMENT}
    ${LIKERT_SCALE_LEGEND_FRAGMENT}
`;

export interface LikertScaleHeaderType {
    id: string;
    content: ContentType;
    scale?: LikertScaleLegendType;
}

export default LikertScaleHeader;
