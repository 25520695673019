import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Content from "../../../Content/Content";
import { required } from "../../../ErrorMessage/rules";
import LikertScaleButton from "../../LikertScaleButton/LikertScaleButton";
import { getLikertCols, ItemProps } from "../LikertScaleItem";
import style from "./LikertScaleClassicItem.module.css"

const LikertScaleClassicItem: FC<ItemProps> = (
    {
        name,
        scale,
        ordinalNumber,
        defaultValue,
        nthOfType,
        onChange,
        children,
        required: isRequired
    }
) => {
    const form = useFormContext();

    const buttons = scale.descriptions.map((description, idx) => {
        const formValue = form && form.watch(name)
        const value = `${description.value}`;

        const enableFocus = (form && !form.getValues(name) && !defaultValue && !idx) // focus on first button if is none selected [WCAG]
            || defaultValue === value || (formValue && formValue === value) // focus on already selected field [WCAG]

        return (
            <div className="text-center col" key={description.id}>
                <LikertScaleButton
                    disabled={!form}
                    defaultChecked={defaultValue === value}
                    value={value}
                    name={name}
                    onChange={onChange}
                    tabIndex={form && enableFocus ? 0: -1}
                    ref={form ? form.register(isRequired ? {required} : {}) : null}
                >
                    <Content content={description.content}/>
                </LikertScaleButton>
            </div>
        );
    });

    const even = nthOfType % 2;

    const cols = getLikertCols();

    return (
        <div className={`col-12 py-3 py-md-2 border-bottom ${even ? "bg-light" : "bg-white"} ${style.wrapper}`}>
            <div className="row align-items-baseline">
                <div className={`${cols.question} d-flex ${style.text}`}>
                    <div className="mr-3">
                      <span dangerouslySetInnerHTML={{__html: ordinalNumber}}/>
                    </div>

                    <div>{children}</div>
                </div>
                <div className={`${cols.options} mt-2 mt-md-0 ml-4 ml-md-0 pr-4 px-md-0 align-self-center`}>
                    <div className="row justify-content-center justify-content-md-between no-gutters">
                        {buttons}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LikertScaleClassicItem;
