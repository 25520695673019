import { gql } from "apollo-boost";
import { default as React, FC } from "react";
import checkOrpheans from "../Assets/checkOrpheans";
import ExaminedPersonData, {
    EXAMINED_PERSON_DATA_FRAGMENT,
    ExaminedPersonDataType
} from "../ExaminedPersonData/ExaminedPersonData";
import ContentEditable from "./ContentEditable";
import Image, { IMAGE_FRAGMENT } from "../Image/Image";
import {useSelector} from "react-redux";
import {examinedPersonSelector} from "../../store/examinedPerson/examinedPersonSelectors";
import {langSelector} from "../../store/lang/langSelectors";
import {LangState} from "../../store/lang/langReducer";

export interface ContentProps {
    content: ContentType;
    editableFields?: boolean;
}

const Content: FC<ContentProps> = ({content, editableFields = false}) => { 
    const examinedPerson = useSelector(examinedPersonSelector);
    const currentLang = useSelector(langSelector)
    const fullName = (examinedPerson && examinedPerson.name && examinedPerson.surname)
        ? `${examinedPerson.name} ${examinedPerson.surname}`
        : "{osoba badana}";

    switch (content.__typename) {
        case "TextContent": {
            let text = content.text
            if (currentLang === LangState.RUS) text = content.russianText || content.text
            if (currentLang === LangState.UK) text = content.ukrainianText || content.text
            if (currentLang === LangState.EN) text = content.englishText || content.text

            const contentText = text.replace("{{ EXAMINED_PERSON_FULLNAME }}", fullName)

            return editableFields
                ? <ContentEditable content={content}/>
                : <span
                    style={{ whiteSpace: "break-spaces" }}
                    dangerouslySetInnerHTML={{
                        __html: checkOrpheans(contentText)
                    }}
                />;
        }
        case "ImageContent":
            return <Image img={content}/>;
        case "ExaminedPersonData":
            return <ExaminedPersonData person={content.person}/>;
        case "ContentList":
            const elements = content.elements.map((c, i) => (
                <div
                    key={c.id}
                    className={i + 1 !== content.elements.length ? "mb-2" : ""}
                >
                    <Content content={c} editableFields={editableFields}/>
                </div>
            ));

            return (
                <>{elements}</>
            );
    }
};

export const CONTENT_FRAGMENT = gql`
    fragment Content on Content {
        id
        ...on TextContent {
            text
            ukrainianText
            russianText
            englishText
        }
        ...ImageContent
        ...ExaminedPersonData
        ... on ContentList {
            elements {
                id
                ... on TextContent { 
                    text
                    ukrainianText
                    russianText
                    englishText
                }
                ...ImageContent
                ...ExaminedPersonData
            }
        }
    }
    ${IMAGE_FRAGMENT}
    ${EXAMINED_PERSON_DATA_FRAGMENT}
`;

export type ContentType = TextContentType | ContentListType | ExaminedPersonDataType | ImageContentType;

export interface TextContentType {
    __typename: "TextContent";
    id: string;
    text: string;
    ukrainianText?: string | null;
    russianText?: string | null;
    englishText?: string | null;
}

interface ImageContentType {
    __typename: "ImageContent";
    id: string;
    url: string;
    attributes?: string[];
    ukrainianUrl?: string;
    russianUrl?: string;
    englishUrl?: string;
    alt?: string;
    ukrainianAlt?: string;
    russianAlt?: string;
    englishAlt?: string;
}

interface ContentListType {
    __typename: "ContentList";
    id: string;
    elements: ContentType[];
}

export default Content;
