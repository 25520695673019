import { gql } from "apollo-boost";
import autosize from "autosize";
import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import Content, { CONTENT_FRAGMENT, ContentType } from "../Content/Content";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { required } from "../ErrorMessage/rules";
import { useDispatch } from "react-redux";
import { examinationAnswer } from "../../store/pendingExaminationAnswers/pendingExaminationAnswersActions";
import styles from "./textQuestion.module.css";

type TextQuestionProps = {
    element: TextQuestionType;
    editableFields?: boolean;
}

const TextQuestion: FC<TextQuestionProps> = ({ element, editableFields }) => {
    const dispatch = useDispatch()

    const textarea = useRef<HTMLElement>();
    const form = useFormContext();

    const [showMaxLengthInfo, setShowMaxLengthInfo] = useState(false)

    useEffect(() => {
        if (textarea.current) {
            autosize(textarea.current);
        }
    });

    const ref = useCallback((ref) => {
        textarea.current = ref;
        if (form) {
            form.register(element.required ? {required} : {})(ref);
        }
    }, [textarea, form, element]);

    const saveAnswer = async (event: ChangeEvent<HTMLTextAreaElement>) => {
        const rawValue = event.currentTarget.value;
        setShowMaxLengthInfo(rawValue.length >= 200)

        dispatch(examinationAnswer({
            questionCode: element.code,
            answer: rawValue
        }))
    }

    const defaultValue = element.answer ? JSON.parse(element.answer) : undefined;

    return (
        <div className="row w-100 mt-3 col-12 no-gutters">
            <div className="col-4 d-flex pr-3">
                <span dangerouslySetInnerHTML={{__html: element.ordinalNumber}}/>
                &nbsp;
                <Content content={element.content} editableFields={editableFields}/>
            </div>

            <div className="col-8">
                <textarea
                    name={element.code}
                    className={`${styles.text} form-control`}
                    defaultValue={defaultValue}
                    onChange={(value) => saveAnswer(value)}
                    disabled={!form}
                    maxLength={200}
                    ref={ref}
                />

                { form
                    ? <span className={styles.maxLengthInfo}>Maksymalna liczba znaków: 200</span>
                    : null
                }

                <ErrorMessage name={element.code}/>
                { showMaxLengthInfo
                    ? <div className="text-info">
                        Osiągnięto maksymalną liczbę znaków (200)
                    </div>
                    : null
                }
            </div>
        </div>
    );
};

export interface TextQuestionType {
    id: string;
    content: ContentType;
    ordinalNumber: string;
    code: string;
    answer?: string;
    required: boolean;
}

export const TEXT_QUESTION_FRAGMENT = gql`
    fragment TextQuestion on TextQuestion {
        id
        content {
            id
            ...Content
        }
        code
        required
        ordinalNumber
        answer(examinationId: $examinationId) @skip(if: $withoutExamination)
    }
    ${CONTENT_FRAGMENT}
`;

export default TextQuestion;
