import React, { FC, ReactElement } from "react";
import BlankLayout from "../../layouts/BlankLayout/BlankLayout";

interface ErrorPageProps {
    message: string;
    description?: string;
    button?: ReactElement;
}

const ErrorPage: FC<ErrorPageProps> = (props) => {
    return (
        <BlankLayout title={props.message} withoutHelmet>
            <div className="row">
                <div className="col-md-5 text-sm-center text-md-right">
                    <div className="bg-light rounded-circle d-inline-block p-sm-0 p-md-5">
                        <i className="far fa-sad-tear fa-10x"/>
                    </div>
                </div>
                <div className="col-md-7 align-self-center text-sm-center text-md-left mt-sm-5 mt-md-0">
                    <div className="align-middle">
                        <h1>{props.message}</h1>
                        {props.description ? <p>{props.description}</p> : null}
                        {props.button}
                    </div>
                </div>
            </div>
        </BlankLayout>
    );
};

export default ErrorPage;
