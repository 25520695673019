import React, {FC} from 'react'
import {SurveyAnswerType, SurveyElementScaleDescription, SurveyElementType} from "../Survey/Survey.types";
import LikertScaleItems from "../LikertScaleElement/LikertScaleItems/LikertScaleItems";
import {LikertScaleItemType, PresentationType} from "../LikertScaleElement/LikertPresentationTypesItems/LikertScaleItem";
import {QuestionnaireElementType} from "../QuestionnaireElement/QuestionnaireElement";

type SurveyLikertElement = {
    __typename: string;
} & LikertScaleItemType

interface SurveyElementProps {
    element: SurveyElementType;
    answers: SurveyAnswerType[];
}

const SurveyLikertElement: FC<SurveyElementProps> = ({element, answers}) => {
    const likertItemCommonProps = {
        __typename: "LikertScaleItem",
        legendType: "HORIZONTAL" as "HORIZONTAL",
        presentationType: PresentationType.CLASSIC,
        required: true,
    }

    const scale = {
        id: "scale",
        question: element.scale.question
            ? {
                __typename: "TextContent",
                id: "question",
                text: element.scale.question,
            }
            : undefined,
        descriptions: element.scale.descriptions.map((description: SurveyElementScaleDescription, idx: number) => ({
            id: `surveyElementScaleDescription${idx}`,
            value: parseInt(description.value) as number,
            content: {
                __typename: "TextContent" as "TextContent",
                id: `surveyElementScaleDescriptionContent${idx}`,
                text: description.value,
            },
            description: {
                __typename: "TextContent" as "TextContent",
                id:`surveyElementScaleDescriptionText{idx}`,
                text: description.text,
            },
        }))
    }

    const likertElements: SurveyLikertElement[] = element.questions.map(question => {
        const answerItem = answers.find(answer => answer.code === question.code)

        return {
            ...likertItemCommonProps,
            scale,
            id: question.code,
            content: {
                __typename: "TextContent",
                id: question.text,
                text: question.text,
            },
            ordinalNumber: question.ordinalNumber + ".",
            code: question.code,
            answer: answerItem ? answerItem.value : undefined,
        }
    })

    return (
        <LikertScaleItems
            elements={likertElements as QuestionnaireElementType[]}
        />
    )
}

export default SurveyLikertElement

