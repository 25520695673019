import React, { FC } from "react";
import LikertScaleQuestion from "../LikertScaleQuestion/LikertScaleQuestion";
import QuestionnaireElement, { QuestionnaireElementType } from "../../QuestionnaireElement/QuestionnaireElement";

export interface LikertScaleItemsProps {
    elements: Array<Item>
    columns?: number;
    editableFields?: boolean;
}

type Item = QuestionnaireElementType;

const LikertScaleItems: FC<LikertScaleItemsProps> = (
    {
        elements,
        columns = 1,
        editableFields = false
    }
) => {
    const question = elements.find(isItem) as QuestionnaireElementType;
    const itemsPerColumn = Math.ceil(elements.length / columns);

    let prevColumnSize = 0;
    const items = elements.reduce((cols, element) => {
        if (prevColumnSize >= itemsPerColumn) {
            prevColumnSize = 0;
            cols.push([]);
        }
        const lastCol = cols[cols.length - 1];
        lastCol.push(element);
        if (isItem(element)) ++prevColumnSize;

        return cols;
    }, [[]] as Item[][]);

    const grid = Math.floor(12/columns);
    const md = Math.max(6, grid);
    const lg = Math.max(4, grid);

    return (
        <div className="col-12 row no-gutters">
            <LikertScaleQuestion
              element={question}
              editableFields={editableFields}
            />

            { items.map((column, idx: number) => (
                <div
                    key={idx}
                    className={
                        `col-12 col-md-${md} col-lg-${lg}
                        ${ columns === 2 ? (!idx ? "pr-0 pr-md-2" : "pl-0 pl-md-2") : null }`
                    }
                >
                    { column.map((item, itemIdx: number) => (
                        <div className="row" key={item.id}>
                            <QuestionnaireElement
                                nthOfType={itemIdx}
                                element={item}
                                isLast={false}
                                editableFields={editableFields}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

const isItem = (item: QuestionnaireElementType) => item.__typename === 'LikertScaleItem';
export const shouldBeInLikertWrapper = (
    element: QuestionnaireElementType,
    prevElement?: QuestionnaireElementType,
    nextElement?: QuestionnaireElementType
) => {
    if(isItem(element)) return true;

    if (!['Anchor', 'ContentElement'].includes(element.__typename)) {
        return false;
    }

    const neighbor = [prevElement, nextElement]
        .find((e) => e && isItem(e));

    return null != neighbor;
};

export default LikertScaleItems;
