import React, { FC } from "react";
import BlankLayout from "../../layouts/BlankLayout/BlankLayout";
import LoadingScreen from "./LoadingScreen";

const LoadingPage: FC =() => (
    <BlankLayout title="Narzędzie PPP">
        <LoadingScreen />
    </BlankLayout>
);

export default LoadingPage;
