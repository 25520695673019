import { connectRouter } from "connected-react-router"
import { History } from "history";
import { combineReducers } from "redux";
import errorScreenReducer from "../components/ErrorScreen/store/errorScreenReducer";
import versionReducer from "./version/versionReducer";
import pendingExaminationAnswersReducer from "./pendingExaminationAnswers/pendingExaminationAnswersReducer"
import examinationIdReducer from "./examinationId/examinationIdReducer"
import examinationNameReducer from "./examinationName/examinationNameReducer"
import surveyIdReducer from "./surveyId/surveyIdReducer"
import examinedPersonReducer from "./examinedPerson/examinedPersonReducer"
import { wcagReducer } from "./wcag/wcagReducer";
import trosExaminationReducer from "./trosExamination/trosExaminationReducer";
import {langReducer} from "./lang/langReducer";

export default (history: History) => combineReducers({
    router: connectRouter(history),
    errorScreen: errorScreenReducer,
    version: versionReducer,
    pendingExaminationAnswers: pendingExaminationAnswersReducer,
    examinationId: examinationIdReducer,
    examinationName: examinationNameReducer,
    trosExamination: trosExaminationReducer,
    surveyId: surveyIdReducer,
    examinedPerson: examinedPersonReducer,
    wcag: wcagReducer,
    lang: langReducer,
});
