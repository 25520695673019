import { gql } from "apollo-boost";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { examinationAnswer } from "../../../store/pendingExaminationAnswers/pendingExaminationAnswersActions";
import Content, { CONTENT_FRAGMENT, ContentType } from "../../Content/Content";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import LikertScaleClassicItem from "./LikertScaleClassicItem/LikertScaleClassicItem";
import LikertScaleCompactItem from "./LikertScaleCompactItem/LikertScaleCompactItem";
import LikertScaleImageItem from "./LikertScaleImageItem/LikertScaleImageItem";
import LikertScaleTextButtonItem from "./LikertScaleTextButtonItem/LikertScaleTextButtonItem";
import LikertScalePlainTextItem from "./LikertScalePlainTextItem/LikertScalePlainTextItem";
import LikertScaleBtnGroupItem from "./LikertScaleBtnGroupItem/LikertScaleBtnGroupItem"

interface LikertScaleItemProps {
    nthOfType: number;
    element: LikertScaleItemType;
    editableFields?: boolean;
}

export enum PresentationType {
    CLASSIC = "CLASSIC",
    COMPACT = "COMPACT",
    IMAGE = "IMAGE",
    TEXT_BUTTON = "TEXT_BUTTON",
    BTN_GROUP = "BTN_GROUP",
    PLAIN_TEXT = "PLAIN_TEXT",
}

export const getLikertCols = () => {
    const md = 7
    const lg = 5

    return {
        question: `col-12 col-md-${12-md} col-lg-${12-lg}`,
        options: `col-12 col-md-${md} col-lg-${lg}`,
    };
};

const LikertScaleItem: FC<LikertScaleItemProps> = ({ element, nthOfType, editableFields = false }) => {
    const dispatch = useDispatch()
    const {code, answer, ordinalNumber, scale, content, required} = element;
    const defaultValue = null == answer ? undefined : `${answer}`;

    const onChange = async (rawValue: string) => {
        dispatch(examinationAnswer({
            questionCode: code,
            answer: parseInt(rawValue)
        }))
    };

    const props: ItemProps = {defaultValue, name: code, ordinalNumber, scale, nthOfType, onChange, required};

    switch (element.presentationType) {
        case PresentationType.COMPACT:
            return <LikertScaleCompactItem {...props}>
                <Content content={content} editableFields={editableFields}/>
                <ErrorMessage name={code}/>
            </LikertScaleCompactItem>
        case PresentationType.CLASSIC:
            return <LikertScaleClassicItem {...props}>
                <Content content={content} editableFields={editableFields}/>
                <ErrorMessage name={code}/>
            </LikertScaleClassicItem>
        case PresentationType.TEXT_BUTTON:
            return <LikertScaleTextButtonItem
                {...props}
                editableFields={editableFields}
            >
                <Content content={content} editableFields={editableFields}/>
                <ErrorMessage name={code}/>
            </LikertScaleTextButtonItem>
        case PresentationType.PLAIN_TEXT:
            return <LikertScalePlainTextItem
                {...props}
                content={content}
                editableFields={editableFields}
            >
                <ErrorMessage name={code}/>
            </LikertScalePlainTextItem>
        case PresentationType.BTN_GROUP:
            return <LikertScaleBtnGroupItem
              {...props}
              content={content}
              editableFields={editableFields}
            >
                <ErrorMessage name={code}/>
            </LikertScaleBtnGroupItem>
        case PresentationType.IMAGE:
            return <LikertScaleImageItem
                {...props}
                content={content}
                editableFields={editableFields}
            >
                <ErrorMessage name={code}/>
            </LikertScaleImageItem>
        default:
            return null;
    }
};

export interface ItemProps {
    scale: LikertScaleItemType["scale"];
    name: string;
    ordinalNumber: string;
    nthOfType: number;
    defaultValue: string | undefined;
    onChange(value: string): void;
    required: boolean;
    editableFields?: boolean;
    content?: ContentType;
}

export const LIEKRT_SCALE_ITEM_FRAGMENT = gql`
    fragment LikertScaleItem on LikertScaleItem {
        id
        code
        content {
            id
            ...Content
        }
        answer(examinationId: $examinationId) @skip(if: $withoutExamination)
        ordinalNumber
        presentationType
        legendType
        required
        scale {
            id
            descriptions {
                id
                value
                content {
                    id
                    ...Content 
                }
                description {
                    id
                    ...Content
                }
            }
        }
    }
    ${CONTENT_FRAGMENT}
`;

export interface LikertScaleItemType {
    id: string;
    code: string;
    content: ContentType;
    answer?: string;
    ordinalNumber: string;
    presentationType: PresentationType;
    legendType: "HORIZONTAL" | "DIAGONAL";
    scale: {
        id: string;
        descriptions: Array<{
            id: string;
            value: number;
            content: ContentType;
            description: ContentType;
        }>;
    },
    required: boolean
}

export default LikertScaleItem;
