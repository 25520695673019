import React, { FC } from "react";
import style from "../SelectCompactQuestion/selectCompactQuestion.module.css";
import selectButtonStyle from "../SelectQuestionButton/selectQuestionButton.module.css";
import {InstructionProps} from "./SelectQuestionaireInstruction";
import {useSelector} from "react-redux";
import {wcagSelector} from "../../../store/wcag/wcagSelectors";
import {WcagState} from "../../../store/wcag/wcagReducer";
import { langSelector } from "../../../store/lang/langSelectors";
import { instructionTranslations } from "./instructionTranslations";
import { LangState } from "../../../store/lang/langReducer";

const SelectQuestionCompactPrenurseryInstruction: FC<InstructionProps> = ({isFemale, isMale, fullName}) => {
    const highContrast = useSelector(wcagSelector) === WcagState.CONTRAST
    const currentLang = useSelector(langSelector)

    const customButton = (selected: boolean, textContent: string) => (
        <>
            <div
                style={{ border: "1px solid black", color : selected ? "white" : "black", background: selected ? "black" : "" }}
                className={`
                ${selectButtonStyle.compactButton} 
                ${ highContrast ? selectButtonStyle.heightContrastButton : ""}
            `}
            >
                { highContrast
                    ? <span>{textContent}</span>
                    : null
                }
            </div>

            { !highContrast && (
                <>
                    <span className="d-md-none mx-2">-</span>
                    <span>{ textContent }</span>
                </>
            )}
        </>
    )

    const question = (selectedButtonIdx: number) => {
        return (
            <div className={`col-12 ${style.instructionQuestionWrapper}`}>
                <div className="col-12">
                    <div className="font-weight-bold my-3 my-md-0 p-3">
                    {instructionTranslations.questionPlayWithAnyToys[currentLang]}
                    </div>
                </div>

                <div className="row px-2 justify-content-between flex-column flex-md-row">
                    <div className={`p-3 col-12 col-md-5 d-flex flex-column justify-content-between`}>
                        <span className={`mb-3 p-3 text-center ${style.group} `}>
                            {instructionTranslations.questionSoftToys[currentLang]}
                        </span>

                        <div className="row flex-column flex-md-row justify-content-around">
                            <div className="row flex-md-column align-items-center ml-3 ml-md-0 mb-2 mb-md-0">
                                { customButton(selectedButtonIdx === 0, instructionTranslations.answerDefinitelyYes[currentLang]) }
                            </div>

                            <div className="row flex-md-column align-items-center ml-3 ml-md-0">
                                { customButton(selectedButtonIdx === 1, instructionTranslations.answerRatherYes[currentLang]) }
                            </div>
                        </div>
                    </div>

                    <div className={`p-3 col-12 col-md-1 d-flex flex-column justify-content-between`}>
                        <div className="d-flex justify-content-center align-items-center pt-2">
                            <span className={`text-center ${style.circleBorder} ${style.circleBorderInstruction}`}>
                                {instructionTranslations.questionOr[currentLang]}
                            </span>
                        </div>

                        <div className="row flex-column flex-md-row justify-content-center">
                            <div className="row flex-md-column align-items-center ml-3 ml-md-0 mr-0">
                                { customButton(selectedButtonIdx === 2, instructionTranslations.answerDifferently[currentLang]) }
                            </div>
                        </div>
                    </div>

                    <div className={`p-3 col-12 col-md-5 d-flex flex-column justify-content-between`}>
                        <span className={`mb-3 p-3 text-center ${style.group} `}>
                            {instructionTranslations.questionGlowingToys[currentLang]}
                        </span>

                        <div className="row flex-column flex-md-row justify-content-around">
                            <div className="row flex-md-column align-items-center ml-3 ml-md-0 mb-2 mb-md-0">
                                { customButton(selectedButtonIdx === 3, instructionTranslations.answerRatherYes[currentLang]) }
                            </div>

                            <div className="row flex-md-column align-items-center ml-3 ml-md-0">
                                { customButton(selectedButtonIdx === 4, instructionTranslations.answerDefinitelyYes[currentLang]) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <section className="col-12 row mt-4 no-gutters px-0 px-md-3">
                <div className="d-none d-md-block col-1"/>
                <div className="col-12 col-md-10">
                    <h2 className={`${style.group} p-3 text-justify`}>
                        <span className={style.instructionHeaderText}>
                            {instructionTranslations.instructionHeaderHowChildBehave[currentLang]}
                            {" "}{ fullName || instructionTranslations.instructionHeaderYourChild[currentLang] },
                            {" "}{instructionTranslations.instructionHeaderDoesOrFeels[currentLang]}
                        </span>

                        <span className={style.instructionHeaderText}>
                            {instructionTranslations.instructionHeaderDecideReactionFromTwoDescriptions[currentLang]}
                            { currentLang === LangState.PL && (isFemale ? " niej " : isMale ? " niego " : " niego/niej ")}
                            {instructionTranslations.instructionHeaderFitLeftOrRight[currentLang]}
                        </span>

                        <span className={style.instructionHeaderText}>
                            {instructionTranslations.instructionHeaderDecideFitFromTwoDescriptions[currentLang]}
                            { currentLang === LangState.PL && (isFemale ? " niej " : isMale ? " niego " : " niego/niej ")}
                            {instructionTranslations.instructionHeaderChooseOption[currentLang]}
                        </span>

                        <span className={style.instructionHeaderText}>
                            {instructionTranslations.instructionHeaderVariedBehavior[currentLang]}
                            {currentLang === LangState.PL && (isFemale ? " jej " : isMale ? " go " : " go/jej ")}
                            {instructionTranslations.instructionHeaderVariedBehavior2[currentLang]}
                        </span>

                        <span className={style.instructionHeaderText}>
                        {instructionTranslations.instructionHeaderNoCorrectAnswer[currentLang]}
                        </span>
                    </h2>
                </div>

                <div className="col-12 mt-5 font-weight-bold">
                    <span className="text-uppercase">{instructionTranslations.example[currentLang]}</span>
                </div>

                { question(0) }

                <div className="my-3">
                    <span className={style.instructionHeaderTextSmall}>                    
                        {instructionTranslations.instructionHeaderPreferToPlayWithSoftToys[currentLang]}
                    </span>

                    <span className={style.instructionHeaderTextSmall}>
                        {instructionTranslations.instructionHeaderRightDescription[currentLang]}
                        { currentLang === LangState.PL && (isFemale ? " niej " : isMale ? " niego " : " niego/niej ")}
                        {instructionTranslations.instructionHeaderRightDescription2[currentLang]}
                    </span>
                </div>

                { question(3) }
            </section>
        </>
    );
};

export default SelectQuestionCompactPrenurseryInstruction;
