import { gql } from "apollo-boost";
import { default as React, FC } from "react";
import style from "./image.module.css"
import {useSelector} from "react-redux";
import {wcagSelector} from "../../store/wcag/wcagSelectors";
import {WcagState} from "../../store/wcag/wcagReducer";
import { langSelector } from "../../store/lang/langSelectors";
import { LangState } from "../../store/lang/langReducer";
export interface ImageProps {
    img?: ImageContent;
    showAlways?: boolean;
}

const Image: FC<ImageProps> = ({img, showAlways = false}) => {
    const highContrast = useSelector(wcagSelector) === WcagState.CONTRAST
    const currentLang = useSelector(langSelector)

    const currentLangImage = () => {
        const langUrl = () => {
            switch(currentLang) {
                case LangState.PL: return img?.url
                case LangState.EN: return img?.englishUrl
                case LangState.RUS: return img?.russianUrl
                case LangState.UK: return img?.ukrainianUrl
            }
        }
        return langUrl() ?? img?.url
    }

    const currentLangAlt = () => {
        const langAlt = () => {
            switch(currentLang) {
                case LangState.PL: return img?.alt
                case LangState.EN: return img?.englishAlt
                case LangState.RUS: return img?.russianAlt
                case LangState.UK: return img?.ukrainianAlt
            }
        }

        return langAlt() ?? img?.alt
    }

    const insertStyle = (img && img.attributes)
        ? img.attributes.map(attr => style[attr]).join(' ')
        : ''

    if (img && img.url && (!highContrast || showAlways)) {
        return <>
            <img
                src={currentLangImage()}
                alt={currentLangAlt() || ""}
                className={`mh-100 mw-100 ${insertStyle}`}
            />
        </>
    } else if (img && img.alt && highContrast) {
        return <span>{ img.alt }</span>
    } else {
        return null
    }
};

export interface ImageContent {
    url: string;
    ukrainianUrl?: string;
    russianUrl?: string;
    englishUrl?: string;
    alt?: string;
    ukrainianAlt?: string;
    russianAlt?: string;
    englishAlt?: string;
    attributes?: string[];
}

export const IMAGE_FRAGMENT = gql`
    fragment ImageContent on ImageContent {
        id
        url
        ukrainianUrl
        russianUrl
        englishUrl
        alt
        ukrainianAlt
        russianAlt
        englishAlt
        attributes
    }
`;

export default Image;
