import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./pageBreak.module.css";
import SponsorsLogo from "../Logo/SponsorsLogo";
import {useSelector} from "react-redux";
import {isTrosTool} from "../../store/trosExamination/trosExaminationSelectors";

interface PageBreakProps {
    isLast?: boolean;
}

const PageBreak:FC<PageBreakProps> = ({isLast}) => {
    const form = useFormContext();
    const isTros = useSelector(isTrosTool)

    if (!form && isLast) {
        return <SponsorsLogo tros={isTros} />;
    }

    if (form || isLast) {
        return null
    }

    return (
        <div className={`col-12 ${styles.pageBreak}`}>
            <div className={styles.text}>Koniec strony</div>
            <div className={styles.divider} />
        </div>
    );
};

export default PageBreak;
