import React, { FC } from "react";
import maleImg from "./male.png";
import answerAImg from "./answerA.png";
import answerBImg from "./answerB.png";
import style from "../../SelectChildQuestion/selectChildQuestion.module.css";
import arrows from "../../SelectChildQuestion/selectQuestionArrows.png";
import {InstructionProps} from "../SelectQuestionaireInstruction";
import {useSelector} from "react-redux";
import {wcagSelector} from "../../../../store/wcag/wcagSelectors";
import {WcagState} from "../../../../store/wcag/wcagReducer";
import { instructionTranslations } from "../instructionTranslations"; 
import { langSelector } from "../../../../store/lang/langSelectors";

const SelectQuestionChildImageMaleInstruction: FC<InstructionProps> = () => {
    const highContrast = useSelector(wcagSelector) === WcagState.CONTRAST
    const bcgButtonStyle = { background: highContrast ? "yellow" : "transparent" }
    const currentLang = useSelector(langSelector)

    return (
      <>
          <section className="col-12 row mt-4 no-gutters px-0 px-md-3">
              <div className="d-none d-md-block col-1"/>
              <div className="col-12 col-md-10 row">
                  <div className={`${style.group} ${style.instructionHeader} px-2`}>
                      <span className={style.instructionHeaderText}>{instructionTranslations.instructionHeaderNextPages[currentLang]}</span>
                      <span className={style.instructionHeaderText}>{instructionTranslations.instructionHeaderHowDoYouBehave[currentLang]}</span>

                      { !highContrast && (
                        <>
                            <span className={style.instructionHeaderText}>{instructionTranslations.instructionHeaderBoyDifferentBehaviors[currentLang]}</span>
                            <span className={style.instructionHeaderText}>{instructionTranslations.instructionHeaderBoyOutfit[currentLang]}</span>
                            <span className={style.instructionHeaderText}>{instructionTranslations.instructionHeaderHereHeIs[currentLang]}</span>
                        </>
                      )}
                  </div>

                  <div className="col-4">
                      { !highContrast && (
                        <img
                          src={maleImg}
                          alt=""
                          className={style.maleImg}
                        />
                      )}
                  </div>

                  <div className="col-8">
                      <div className={`${style.group} ${style.instructionHeader} px-2`}>
                            <span className={style.instructionHeaderText}>{instructionTranslations.instructionHeaderSuitableForYou[currentLang]}{highContrast? "" : instructionTranslations.instructionHeaderWhatBoyDo[currentLang]}
                                <b className="d-none d-md-inline"> {instructionTranslations.instructionHeaderLeftOrRight[currentLang]}</b>
                                <b className="d-md-none"> {instructionTranslations.instructionHeaderUpOrDown[currentLang]}</b>
                            </span>
                          <span className={style.instructionHeaderText}>{instructionTranslations.instructionHeaderFitALotOrALittle[currentLang]}</span>
                      </div>
                  </div>
              </div>

              <div className="col-12 my-5">
                  <div className={style.ordinalNumber}>
                      <span className="text-uppercase px-2">{instructionTranslations.example[currentLang]}</span>
                  </div>
                  <div className={`p-3 ${style.questionContainer}`}>
                      <div className="text-center font-weight-bold my-3 my-md-0">
                        {instructionTranslations.questionWhenICanDoWhatIWant[currentLang]}
                      </div>
                      <div className="d-none d-md-block text-center">
                          <img src={arrows} alt="Strzałki" className={style.arrows}/>
                      </div>
                      <div className="row px-2 justify-content-between flex-column flex-md-row">
                          <div className={`${style.group} p-3 col-12 col-md-5 d-flex flex-column justify-content-between`}>
                              { !highContrast && (
                                <img
                                  src={answerAImg}
                                  alt=""
                                  className={style.answerImg}
                                />
                              )}

                              <span className="mb-3 text-center">{instructionTranslations.questionToysAtHome[currentLang]}</span>

                              <div className="row flex-column flex-md-row justify-content-around">
                                  <div className="row flex-md-column align-items-center ml-3 ml-md-0 mb-2 mb-md-0">
                                      <div className={`${style.testButton} btn btn-outline-dark rounded-circle`} style={bcgButtonStyle}>
                                          1
                                      </div>
                                      <span className="d-md-none mx-2">-</span>
                                      <span>{instructionTranslations.answerYes[currentLang]}</span>
                                  </div>

                                  <div className="row flex-md-column align-items-center ml-3 ml-md-0">
                                      <div className={`${style.testButton} btn btn-outline-dark rounded-circle`} style={bcgButtonStyle}>
                                          2
                                      </div>
                                      <span className="d-md-none mx-2">-</span>
                                      <span>{instructionTranslations.answerRatherYes[currentLang]}</span>
                                  </div>
                              </div>
                          </div>

                          <div className={`p-3 col-12 col-md-1 d-flex justify-content-md-center`}>
                              <div className="row flex-md-column align-items-center align-self-end ml-0">
                                  <div className={`${style.testButton} btn btn-outline-dark rounded-circle`} style={bcgButtonStyle}>
                                      3
                                  </div>
                                  <span className="d-md-none mx-2">-</span>
                                  <span>{instructionTranslations.answerDifferently[currentLang]}</span>
                              </div>
                          </div>

                          <div className={`${style.group} p-3 col-12 col-md-5 d-flex flex-column justify-content-between`}>
                              { !highContrast && (
                                <img
                                  src={answerBImg}
                                  alt=""
                                  className={style.answerImg}
                                />
                              )}

                              <span className="mb-3 text-center">{instructionTranslations.questionOutsideHouse[currentLang]}</span>

                              <div className="row flex-column flex-md-row justify-content-around">
                                  <div className="row flex-md-column align-items-center ml-3 ml-md-0 mb-2 mb-md-0">
                                      <div className={`${style.testButton} btn btn-outline-dark rounded-circle`} style={bcgButtonStyle}>
                                          4
                                      </div>
                                      <span className="d-md-none mx-2">-</span>
                                      <span>{instructionTranslations.answerRatherYes[currentLang]}</span>
                                  </div>

                                  <div className="row flex-md-column align-items-center ml-3 ml-md-0">
                                      <div className={`${style.testButton} btn btn-outline-dark rounded-circle`} style={bcgButtonStyle}>
                                          5
                                      </div>
                                      <span className="d-md-none mx-2">-</span>
                                      <span>{instructionTranslations.answerYes[currentLang]}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="d-none d-md-block col-1"/>
              <div className="col-12 col-md-10 d-flex justify-content-between flex-column flex-md-row">
                  <div className={`${style.group} ${style.testAnswer} p-4 text-center`}>
                        <span>
                            {instructionTranslations.explanationAtHome[currentLang]}
                        </span>

                      <div className="row flex-nowrap align-items-baseline mt-2">
                          <div className={`${style.testButton} btn btn-outline-dark rounded-circle mr-2`} style={bcgButtonStyle}>
                              1
                          </div>
                          <span className={style.answerSpan}>{instructionTranslations.explanationDefinitelyAtHome[currentLang]}</span>
                      </div>

                      <div className="row flex-nowrap align-items-baseline">
                          <div className={`${style.testButton} btn btn-outline-dark rounded-circle mr-2`} style={bcgButtonStyle}>
                              2
                          </div>
                          <span className={style.answerSpan}>{instructionTranslations.explanationLittleAtHome[currentLang]}</span>
                      </div>
                  </div>

                  <div className={`${style.group} d-md-none p-4 my-4`}>
                        <span className="text-center">
                            {instructionTranslations.explanationSometimesOutdoor[currentLang]}
                            <div className={`${style.testButton} btn btn-outline-dark rounded-circle ml-1`} style={bcgButtonStyle}>
                                3
                            </div>{instructionTranslations.explanationTryToAvoid[currentLang]}
                        </span>
                  </div>

                  <div className={`${style.group} ${style.testAnswer} p-4 text-center`}>
                        <span>
                            {instructionTranslations.explanationOutdoor[currentLang]}
                        </span>

                      <div className="row flex-nowrap align-items-baseline mt-2">
                          <div className={`${style.testButton} btn btn-outline-dark rounded-circle mr-2`} style={bcgButtonStyle}>
                              4
                          </div>
                          <span className={style.answerSpan}>{instructionTranslations.explanationLittleOutdoor[currentLang]}</span>
                      </div>

                      <div className="row flex-nowrap align-items-baseline">
                          <div className={`${style.testButton} btn btn-outline-dark rounded-circle mr-2`} style={bcgButtonStyle}>
                              5
                          </div>
                          <span className={style.answerSpan}>{instructionTranslations.explanationDefinitelyOutdoor[currentLang]}</span>
                      </div>
                  </div>
              </div>
              <div className="d-none d-md-block col-1"/>

              <div className="d-none d-md-block col-1"/>
              <div className={`${style.group} d-none col-md-10 p-3 d-md-flex justify-content-between mt-4`}>
                        <span className="text-center">
                            {instructionTranslations.explanationSometimesOutdoor[currentLang]}
                            <div className={`${style.testButton} btn btn-outline-dark rounded-circle ml-2`} style={bcgButtonStyle}>
                                3
                            </div>{instructionTranslations.explanationTryToAvoid[currentLang]}
                        </span>
              </div>
          </section>
      </>
    );
};

export default SelectQuestionChildImageMaleInstruction;
