import React, { ComponentType } from "react";
import { useSelector } from "react-redux";
import { isAllAnswerSaved } from "../../store/pendingExaminationAnswers/pendingExaminationAnswersSelectors";

const ExaminationFormItemSubmittingStatus: ComponentType = () => {
    const isAnySubmittingItem = !useSelector(isAllAnswerSaved)

    return (
        <div
            style={{ height: "25px" }}
            className="d-flex justify-content-center mt-2"
        >
            <div
                className="d-flex align-items-center"
                style={{ opacity: isAnySubmittingItem ? "1" : "0", transition: "opacity 0.3s" }}
            >
                {
                    isAnySubmittingItem
                    ? <>
                            <div className="spinner-border text-primary mr-2" role="status"/>
                            <span>Trwa zapisywanie odpowiedzi</span>
                    </>
                    : ""
                }
            </div>
        </div>
    )
};

export default ExaminationFormItemSubmittingStatus;
