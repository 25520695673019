import {ConstraintViolation} from "./processResponseException";

const translateViolation = ({code, message}: ConstraintViolation): ConstraintViolation => ({
    code,
    message: defaultViolationMessages[code] || message
});

type ViolationMessages = Readonly<{[code: string]: string}>;

const defaultViolationMessages: ViolationMessages = {
    '07978e40-8ecb-4237-ac21-f447e020ec4a': "Ta wartość jest już zajęta",
    '0a89f710-96bf-4b8b-8e9e-5c5d2931e13c': "Podany adres e-mail istnieje w systemie",
    'c1051bb4-d103-4f74-8988-acbcafc7fdc3': "Ta wartość nie może być pusta",
    '9ff3fdc4-b214-49db-8718-39c315e33d45': "Podana wartosć jest za krótka",
    'd94b19cc-114f-4f44-9cc4-4138e80a87b9': "Podana wartosć jest za długa",
    'de1e3db3-5ed4-4941-aae4-59f3667cc3a3': "Niepoprawny format",
    '57c2f299-1154-4870-89bb-ef3b1f5ad229': "Niepoprawny format",
    '778b7ae0-84d3-481a-9dec-35fdb64b1d78': "Niepoprawna wartość",
    '079d7420-2d13-460c-8756-de810eeb37d2': "Niepoprawna wartość",
    '183ad2de-533d-4796-a439-6d3c3852b549': "To pole nie powinno być puste",
    '0380beb6-f36f-480b-bc15-b1ddfcf744a1': "Pełnoletnia osoba nie powinna posiadać opiekuna",
    '08968371-dd81-45b5-b5fc-7ce649487228': "Ta osoba powinna mieć opiekuna",
    'f86d5e90-0d43-4b73-acb2-7a00b33b18d1': "Dziecko w klasie 1-3 nie powinno mieć własnego adresu e-mail",
    '5760e7e5-621e-4fbe-85ec-9441d7b10d18': "Musi zostać podany przynajmniej jeden adres e-mail (osoby badanej lub opiekuna)",
    'bd79c0ab-ddba-46cc-a703-a7a4b08de310': "Adres e-mail jest niepoprawny"
};

export default translateViolation;
