import React,{FC} from 'react'
import {FormProvider, useForm} from "react-hook-form";
import {gql} from "apollo-boost";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Helmet} from "react-helmet-async";
import SurveyElement from "../SurveyElements/SurveyElement";
import {Redirect} from "react-router";
import LikertScaleHeader from "../LikertScaleElement/LikertScaleHeader/LikertScaleHeader";
import {SurveyElementType, SurveyStatusEnum, SurveyType} from "./Survey.types";
import useSurvey from "../../hooks/useSurvey";
import ExaminationFormItemSubmittingStatus from "../ExaminationCommonItems/ExaminationFormItemSumbittingStatus";
import {useSelector} from "react-redux";
import {isAllAnswerSaved} from "../../store/pendingExaminationAnswers/pendingExaminationAnswersSelectors";

interface SurveyProps {
    surveyId: string;
    preview?: boolean;
}

const Survey: FC<SurveyProps> = ({surveyId, preview = false}) => {
    const form = useForm()
    const isAnySubmittingItem = !useSelector(isAllAnswerSaved)
    useSurvey(surveyId)

    const [closeSurvey, {loading: closingSurvey}] = useMutation(CLOSE_SURVEY_MUTATION, {
        variables: { surveyId },
    });

    const disableButton = isAnySubmittingItem || closingSurvey;

    const {data, loading} = useQuery<SurveyType>(SURVEY_QUERY, {
        variables: {
            surveyId
        }
    })

    if (loading) {
        return (
            <div className="jumbotron">
                <h1>
                    <i className="fa fa-spin fa-spinner"/>
                    {' '}
                    Trwa wczytywanie...
                </h1>
            </div>);
    }

    const survey = data ? data.surveyById : null;
    if (!survey) {
        return <Redirect to="/404"/>
    }

    if (survey!.status === SurveyStatusEnum.CLOSED && !preview) {
        return <Redirect to={`/survey/${surveyId}/thanks`} />;
    }

    const handleSubmit = form
        ? form.handleSubmit(async () => {
            closeSurvey().catch(() => {
                document.location.reload();
            });
        })
        : () => {};

    const surveyElements = survey!.tool.elements.map((item: SurveyElementType) => (
        <div className="mb-5">
            <SurveyElement
                element={item}
                answers={survey!.answers}
            />
        </div>
    ))

    return (
        <form onSubmit={handleSubmit}>
            <Helmet>
                <title>{survey ? `Ankieta - ${survey.tool.name}` : "Ankieta"}</title>
            </Helmet>
            <div className={"mb-5"}>
                <h1 className={"mb-5"}>Ankieta - {survey!.tool.name}</h1>

                <LikertScaleHeader element={{
                    id: survey!.tool.id,
                    content: {
                        __typename: "TextContent",
                        id: survey!.tool.description,
                        text: survey!.tool.description
                    }
                }}/>
            </div>

            <div className="my-3">
                { !preview
                    ? (
                        <FormProvider{...form}>
                            { surveyElements }
                        </FormProvider>
                    )
                    : surveyElements
                }
            </div>

            <ExaminationFormItemSubmittingStatus/>

            { !preview && (
                <div>
                    <button
                        type="submit"
                        style={{ pointerEvents: disableButton ? "none" : "auto" }}
                        className="btn btn-success float-right"
                        disabled={disableButton}
                    >
                        <i className={`fa fa-${disableButton ? "spinner fa-spin" : "save"} mr-1`}/>
                        Zakończ
                    </button>
                </div>
            )}

        </form>
    )
}

const SURVEY_QUERY = gql`
    query surveyById($surveyId: ID!){
        surveyById(surveyId: $surveyId){
            id
            status
            tool {
                id
                name
                description
                creationDate
                elements {
                    ordinalNumber
                    type
                    scale {
                        question
                        descriptions {
                            value
                            text
                        }
                    }
                    questions {
                        ordinalNumber
                        text
                        code
                    }
                }
            }
            answers {
                code
                value
            }
            creationDate
            closedAt
        }
    }
`

const CLOSE_SURVEY_MUTATION = gql`
    mutation ClosSurveyMutation($surveyId: ID!) {
        surveyClose(surveyId: $surveyId) {
            id
            status
        }
    }
`;

export default Survey
