import React, { FC } from "react";
import MessageLayout from "../../layouts/MessageLayout/MessageLayout";

const RejectionThanksPage: FC = () => {
    return (
        <MessageLayout title="Rezygnacja z badania">
            <p>Dziękujemy za informację - zrezygnowałeś/aś z badania.</p>
        </MessageLayout>
    );
};

export default RejectionThanksPage;
