import { gql } from "apollo-boost";
import React, { FC } from "react";
import Content, { CONTENT_FRAGMENT, ContentType } from "../../Content/Content";
import LikertScaleButton from "../LikertScaleButton/LikertScaleButton";

export interface LikertScaleLegendProps {
    scale: LikertScaleLegendType;
    miniLegend?: boolean;
    editableFields?: boolean;
}

const LikertScaleLegend: FC<LikertScaleLegendProps> = ({scale, miniLegend = false, editableFields = false}) => {
    const itemsWithDescription = scale.descriptions.filter((item) => item.description);

    if (!itemsWithDescription.length) return null;

    const elements = itemsWithDescription.map((item) => (
      <div key={item.id} tabIndex={0} className={`${miniLegend ? "small mr-2" : ""} mb-1 d-flex align-items-center justify-between`}>
          <LikertScaleButton value={`${item.value}`} miniLegend={miniLegend} disabled active>
              <Content content={item.content}/>
          </LikertScaleButton>
          <div className={'mx-1'}>
              {' – '}
          </div>
          <div className={`${ miniLegend ? "d-inline" : ""}` }>
              <Content content={item.description as ContentType} editableFields={editableFields}/>
          </div>
      </div>
    ));

    return (
        <div className={miniLegend ? "w-100 d-flex flex-wrap justify-content-between" : ""}>
            {elements}
        </div>
    );
};

export const LIKERT_SCALE_LEGEND_FRAGMENT = gql`
    fragment LikertScaleLegend on RatingScale {
        id
        descriptions {
            id
            value
            content {
                id
                ...Content
            }
            description {
                id
                ...Content
            }
        }
    }
    ${CONTENT_FRAGMENT}
`;

export interface LikertScaleLegendType {
    id: string;
    descriptions: Array<{
        id: string;
        value: number;
        content: ContentType;
        description?: ContentType;
    }>
}

export default LikertScaleLegend;
