import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Content, { ContentType } from "../../Content/Content";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { required as requiredRule } from "../../ErrorMessage/rules";
import { ScaleItem, SelectComponentQuestionProps } from "../SelectQuestion";
import SelectQuestionButton from "../SelectQuestionButton/SelectQuestionButton";
import style from "./selectCompactQuestion.module.css";
import {useSelector} from "react-redux";
import {langSelector} from "../../../store/lang/langSelectors";
import {LangState} from "../../../store/lang/langReducer";

const SelectQuestion: FC<SelectComponentQuestionProps> = (
    {
        element: {
            code,
            answer,
            required,
            multi,
            ordinalNumber,
            content,
            scale: { descriptions },
        },
        onChange,
        editableFields = false
    }
) => {
    const currentLang = useSelector(langSelector)

    const form = useFormContext();

    const defaultValue = null == answer ? undefined : JSON.parse(answer) as number | number[];
    const groups = descriptions.filter((item) => null == item.value);
    const items = descriptions.filter((item) => null != item.value);

    const isInGroup = (item: ScaleItem) => (group: ScaleItem) => {
        return item.value >= group.rangeFrom && item.value <= group.rangeTo;
    };
    const itemsWithoutGroup = items.filter((item) => !groups.find(isInGroup(item)));
    const roots = [...groups, ...itemsWithoutGroup]
        .sort((a, b) => Math.sign(a.rangeFrom - b.rangeFrom));
    const baseWidth = 12 / items.length;
    const groupWidth = (12 - Math.floor(baseWidth)) / (items.length - itemsWithoutGroup.length);

    let textOr = 'czy'
    if (currentLang === LangState.RUS) textOr = "или"
    if (currentLang === LangState.UK) textOr = "чи"
    if (currentLang === LangState.EN) textOr = "or"
    if (currentLang === LangState.PL) textOr = "czy"

    const renderGroup = (description: ScaleItem, groupIdx: number) => {
        const elements = items.filter((item) => isInGroup(item)(description));
        const isMiddleItem = description.value

        return (
            <div
                key={description.id}
                className={[
                    `col-12 col-md-${Math.floor(groupWidth * elements.length)}`,
                    "p-3",
                    "d-flex flex-column justify-content-between",
                    style.groupContainer
                ].join(" ")}
            >
                <div className="mb-3 h-100 text-center">
                    {
                        !isMiddleItem
                            ?
                                <div className={`
                                    p-3 ${ style.group } h-100
                                    d-flex justify-content-center align-items-center
                                `}>
                                    <Content content={description.content} editableFields={editableFields}/>
                                </div>
                            :
                                <div className={`d-flex justify-content-center align-items-center h-100`}>
                                    <span  className={ style.circleBorder }>
                                        { textOr }
                                    </span>
                                </div>
                    }
                </div>

                <div className="row px-3 flex-column flex-md-row justify-content-md-around">
                    { elements.map((element, idx) => renderItem(element, groupIdx, idx)) }
                </div>
            </div>
        );
    };

    const renderItem = (item: ScaleItem, groupIdx: number, idx: number) => {
        const formValue = form && form.watch(code)
        const enableFocus = multi // enable all for multi question
            || (form && !formValue && !defaultValue && !groupIdx && !idx) // focus on first button if is none selected [WCAG]
            || defaultValue === item.value || (formValue && Number(formValue) === item.value) // focus on already selected field [WCAG]

        return (
            <div
                key={item.id}
                className="text-center d-flex justify-content-md-center"
            >
                <div className="align-self-end d-flex align-items-baseline d-md-block">
                    <SelectQuestionButton
                        value={item.value}
                        displayValue={<Content content={item.content}/>}
                        onChange={onChange}
                        defaultChecked={Array.isArray(defaultValue)
                            ? defaultValue.includes(item.value)
                            : defaultValue === item.value
                        }
                        code={code}
                        presentationType="COMPACT"
                        disabled={!form}
                        multi={multi}
                        tabIndex={form && enableFocus ? 0 : -1}
                        ref={form ? form.register(required ? {required: requiredRule} : {}) : null}
                    >
                        <span className={style.buttonDescription}>
                            <Content
                              content={item.description as ContentType}
                              editableFields={editableFields}
                            />
                        </span>
                    </SelectQuestionButton>
                </div>
            </div>
        );
    };

    return (
        <div className={`${ form && form.errors[code] ? style.invalid : ""} ${ style.lastLine } col-12`}>
            <div className={`px-3 py-4 ${style.questionContainer}`}>
                <div className={`font-weight-bold my-3 my-md-0 ${ style.title }`}>
                    <span className="mr-1" dangerouslySetInnerHTML={{__html: ordinalNumber}}/>

                    <Content content={content} editableFields={editableFields}/>
                </div>

                <div className="row px-2 flex-column flex-md-row justify-content-around">
                    {
                        roots.map((root, groupIdx) => renderGroup(root, groupIdx))
                    }
                </div>
            </div>

            {
                form
                ? <div className={style.errorWrapper}>
                    <ErrorMessage name={code}/>
                  </div>
                : null
            }
        </div>
    );
};

export default SelectQuestion;
