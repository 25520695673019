import { default as React, FC, useState } from "react";
import Content, { TextContentType } from "./Content";
import style from "./contentEditable.module.css"
import Editor from "../Editor/Editor";
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import {required as requiredMessage} from "../ErrorMessage/rules";
import processResponseException from "../RegistrationPage/FormItems/Errors/processResponseException";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const EDIT_CONTENT = gql`
    mutation EditContent($contentId: String!, $text: String!) {
        textContentEdit(contentId: $contentId, text: $text) {
            id
            ...on TextContent {
                text
            }
        }
    }
`;

export interface ContentProps {
    content: TextContentType;
}

const ContentEditable: FC<ContentProps> = ({content}) => {
    Modal.setAppElement('#root')
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

    const form = useForm<{ text: string }>({
        defaultValues: {
            text: ""
        }
    })

    const [editContent, { loading }] = useMutation(EDIT_CONTENT);

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const onSubmit = form.handleSubmit(async (values) => {
        try {
            await editContent({
                variables: {
                    contentId: content.id,
                    text: values.text
                      .replace(/<p>|<\/p>/gi, '') // remove intrusive p tag returning from editor
                      .replace(/<br>/gi, '\n') // replace hard enter by \n to avoid <br> purifier validation error
                      .replace(/&nbsp;/gi, ' ') // replace non-breaking space by normal space to avoid purifier validation error
                      .trim() // remove \n from end of text to avoid purifier validation error
                }
            })

            closeModal()
        } catch (e) {
            processResponseException(form, e);
        }
    })

    return <div className={style.buttonWrapper}>
        <button
          className={style.button}
          title="Edytuj"
          onClick={openModal}
        >
            <Content content={content}/>
        </button>

        <div className={style.error}>
            <ErrorMessage name="text"/>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className={style.modalWrapper}
          shouldCloseOnOverlayClick={false}
        >
            <form
              className={`${style.modalDialog}  modal-dialog`}
              onSubmit={onSubmit}
            >
                <div className={`${style.modalContent} ${loading ? style.modalContentLoading : ''} modal-content`}>
                    <div className="modal-header">
                        <h5 className="modal-title">Edytuj tekst</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Zamknij" onClick={closeModal} title="Zamknij" disabled={loading}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className={`${style.modalBody} modal-body`}>
                        <Editor
                          ref={form.register({required: requiredMessage})}
                          name="text"
                          initialValue={content.text
                            .replace(/<b>/gi, '<strong>')
                            .replace(/<\/b>/gi, '</strong>')
                            .replace(/<i>/gi, '<em>')
                            .replace(/<\/i>/gi, '</em>')
                          }
                        />
                    </div>

                    <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={closeModal}
                          title="Zamknij"
                          disabled={loading}
                        >
                            Zamknij
                        </button>

                        <button
                          type="submit"
                          className="btn btn-primary d-flex align-items-center"
                          title="Zapisz zmiany i zamknij modal"
                          disabled={loading}
                        >
                            { loading &&
                                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"/>
                            }
                            Zapisz
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
}

export default ContentEditable