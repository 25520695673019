import { gql } from "apollo-boost";
import client from "../../client";
import { put, select, call, debounce } from "redux-saga/effects"
import { examinationIdSelector } from "../examinationId/examinationIdSelectors";
import { pendingExaminationAnswers } from "./pendingExaminationAnswersSelectors";
import { examinationAnswerSaved } from "./pendingExaminationAnswersActions";
import { PendingExaminationAnswersTypes } from "./pendingExaminationAnswersTypes";
import { PendingExaminationAnswers } from "./pendingExaminationAnswersReducer";
import { surveyIdSelector } from "../surveyId/surveyIdSelectors";

function* saveAnswerSaga() {
    const examinationId = yield select(examinationIdSelector);
    const surveyId = yield select(surveyIdSelector);
    const pendingAnswers: PendingExaminationAnswers = yield select(pendingExaminationAnswers);

    const answers = Object.keys(pendingAnswers).map(key => {
        return {
            code: key,
            value: JSON.stringify(pendingAnswers[key])
        }
    })

    if (examinationId) {
        yield call(client.mutate, {
            mutation: ANSWER_MUTATION,
            variables: {
                input: {
                    examinationId,
                    answers
                }
            }
        });
    } else if (surveyId) {
        yield call(client.mutate, {
            mutation: SURVEY_ANSWER_MUTATION,
            variables: {
                input: {
                    surveyId,
                    answers
                }
            }
        });
    }

    yield put(examinationAnswerSaved(pendingAnswers))
}

export default function* pendingExaminationAnswersSaga() {
    yield debounce(1500, PendingExaminationAnswersTypes.EXAMINATION_ANSWER, saveAnswerSaga)
}

const ANSWER_MUTATION = gql`
    mutation ExaminationAnswer($input: ExaminationAnswerInput!) {
        examinationAnswer (input: $input) {
            id
        }
    }
`;

const SURVEY_ANSWER_MUTATION = gql`
    mutation SurveyAnswer($input: SurveyAnswersInput!) {
        surveyAnswer (input: $input) {
            id
        }
    }
`;