import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Content, { ContentType } from "../../Content/Content";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { required as requiredRule } from "../../ErrorMessage/rules";
import { ScaleItem, SelectComponentQuestionProps } from "../SelectQuestion";
import SelectQuestionButton from "../SelectQuestionButton/SelectQuestionButton";
import style from "./selectColumnQuestion.module.css";

const SelectQuestion: FC<SelectComponentQuestionProps> = (
    {
        element: {
            code,
            answer,
            required,
            multi,
            ordinalNumber,
            content,
            scale,
        },
        onChange,
        editableFields = false
    }
) => {
    const form = useFormContext();
    const defaultValue = null == answer ? undefined : JSON.parse(answer) as number | number[];

    const renderItem = (item: ScaleItem, idx: number) => {
        const formValue = form && form.watch(code)
        const enableFocus = multi // enable all for multi question
            || (form && !form.getValues(code) && !defaultValue && !idx) // focus on first button if is none selected [WCAG]
            || defaultValue === item.value || (formValue && Number(formValue) === item.value) // focus on already selected field [WCAG]

        return (
            <div
                key={item.id}
                className="col-12 d-flex"
            >
                <div className="align-self-end d-flex align-items-baseline">
                    <SelectQuestionButton
                        value={item.value}
                        displayValue={<Content content={item.content}/>}
                        onChange={onChange}
                        defaultChecked={Array.isArray(defaultValue)
                            ? defaultValue.includes(item.value)
                            : defaultValue === item.value
                        }
                        code={code}
                        presentationType="COLUMN"
                        disabled={!form}
                        multi={multi}
                        tabIndex={form && enableFocus ? 0 : -1}
                        ref={form ? form.register(required ? {required: requiredRule} : {}) : null}
                    >
                        <span className={style.buttonDescription}>
                            <Content
                              editableFields={editableFields}
                              content={item.description as ContentType}
                            />
                        </span>
                    </SelectQuestionButton>
                </div>
            </div>
        );
    };

    return (
        <div className={`${ form && form.errors[code] ? style.invalid : ""} col-12`}>
            <div className={`px-3 py-4 ${style.questionContainer}`}>
                <div className={`font-weight-bold mb-3 ${ style.title }`}>
                    <span className="mr-1" dangerouslySetInnerHTML={{__html: ordinalNumber}}/>

                    <Content content={content} editableFields={editableFields}/>
                </div>

                <div className="row px-2 flex-column flex-md-row justify-content-around">
                    {
                        scale.descriptions
                            .filter(item => item.description)
                            .map((item, idx) => renderItem(item, idx))
                    }
                </div>
            </div>

            {
                form
                ? <div className={style.errorWrapper}>
                    <ErrorMessage name={code}/>
                  </div>
                : null
            }
        </div>
    );
};

export default SelectQuestion;
