import { gql } from "apollo-boost";
import React, { FC } from "react";
import Anchor, { ANCHOR_FRAGMENT, AnchorType } from "../Anchor/Anchor";
import ContentElement, { CONTENT_ELEMENT_FRAGMENT, ContentElementType } from "../ContentElement/ContentElement";
import LikertScaleHeader, { LIKERT_SCALE_HEADER_FRAGMENT, LikertScaleHeaderType } from "../LikertScaleElement/LikertScaleHeader/LikertScaleHeader";
import LikertScaleItem, { LIEKRT_SCALE_ITEM_FRAGMENT, LikertScaleItemType } from "../LikertScaleElement/LikertPresentationTypesItems/LikertScaleItem";
import { LIKERT_SCALE_QUESTION_FRAGMENT, LikertScaleQuestionType } from "../LikertScaleElement/LikertScaleQuestion/LikertScaleQuestion";
import PageBreak from "../PageBreak/PageBreak";
import SelectQuestion, { SELECT_QUESTION_FRAGMENT, SelectQuestionType } from "../SelectQuestion/SelectQuestion";
import SelectQuestionaireInstruction, { SELECT_QUESTION_INSTRUCTION_FRAGMENT, SelectQuestionInstructionType } from "../SelectQuestion/SelectQuestionInstruction/SelectQuestionaireInstruction";
import TextQuestion, { TEXT_QUESTION_FRAGMENT, TextQuestionType } from "../TextQuestion/TextQuestion";
import RankQuestion, { RANK_QUESTION_FRAGMENT, RankQuestionType } from "../RankQuestionElement/RankQuestion";

export interface QuestionnaireElementProps {
  element: QuestionnaireElementType;
  nthOfType: number;
  isLast: boolean;
  editableFields?: boolean;
}

const QuestionnaireElement: FC<QuestionnaireElementProps> = (props) => {
    switch (props.element.__typename) {
        case "LikertScaleHeader":
            return <LikertScaleHeader element={props.element} editableFields={props.editableFields}/>;
        case "LikertScaleItem":
            return <LikertScaleItem nthOfType={props.nthOfType} element={props.element} editableFields={props.editableFields}/>;
        case "Anchor":
            return  <Anchor element={props.element} editableFields={props.editableFields}/>;
        case "PageBreak":
            return <PageBreak isLast={props.isLast}/>;
        case "ContentElement":
            return <ContentElement element={props.element} editableFields={props.editableFields}/>;
        case "TextQuestion":
            return <TextQuestion element={props.element} editableFields={props.editableFields}/>;
        case "SelectQuestionInstruction":
            return <SelectQuestionaireInstruction element={props.element} editableFields={props.editableFields}/>;
        case "SelectQuestion":
            return <SelectQuestion element={props.element} editableFields={props.editableFields}/>;
        case "RankQuestion":
            return <RankQuestion element={props.element} editableFields={props.editableFields}/>
        default:
            return null;
    }
};

export type QuestionnaireElementType = {
    __typename: string;
} &
    LikertScaleHeaderType &
    LikertScaleItemType &
    LikertScaleQuestionType &
    AnchorType &
    TextQuestionType &
    ContentElementType &
    SelectQuestionType &
    RankQuestionType &
    SelectQuestionInstructionType

export const QUESTIONNAIRE_ELEMENT_FRAGMENT = gql`
    fragment QuestionnaireElement on QuestionnaireElement {
        id
        ...LikertScaleHeader
        ...LikertScaleItem
        ...LikertScaleQuestion
        ...Anchor
        ...ContentElement
        ...TextQuestion
        ...SelectQuestion
        ...RankQuestion
        ...SelectQuestionInstruction
    }
    ${LIKERT_SCALE_HEADER_FRAGMENT}
    ${LIEKRT_SCALE_ITEM_FRAGMENT}
    ${LIKERT_SCALE_QUESTION_FRAGMENT}
    ${ANCHOR_FRAGMENT}
    ${CONTENT_ELEMENT_FRAGMENT}
    ${TEXT_QUESTION_FRAGMENT}
    ${SELECT_QUESTION_FRAGMENT}
    ${RANK_QUESTION_FRAGMENT}
    ${SELECT_QUESTION_INSTRUCTION_FRAGMENT}
`;

export default QuestionnaireElement;
