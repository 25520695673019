import { gql } from "apollo-boost";
import React, { FC } from "react";
import Content, { CONTENT_FRAGMENT, ContentType } from "../Content/Content";

type ContentElementProps = {
    element: ContentElementType;
    editableFields?: boolean;
}

const ContentElement: FC<ContentElementProps> = (props) => {
    const hasTwoImagesInRow = props.element.content.__typename === "ImageContent"
      && props.element.content.attributes
      && props.element.content.attributes.some(x => ["left", "right"].includes(x))

    return (
      <div className={`${hasTwoImagesInRow ? "col-6" : "col-12"}`}>
          {props.element.ordinalNumber ? (
            <>
                <span dangerouslySetInnerHTML={{__html: props.element.ordinalNumber}}/>
                &nbsp;
            </>
          ) : null}
          <Content
            content={props.element.content}
            editableFields={props.editableFields || false}
          />
      </div>
    );
};

export interface ContentElementType {
    id: string;
    content: ContentType;
    ordinalNumber?: string;
}

export const CONTENT_ELEMENT_FRAGMENT = gql`
    fragment ContentElement on ContentElement {
        id
        content {
            id
            ...Content
        }
        ordinalNumber
    }
    ${CONTENT_FRAGMENT}
`;

export default ContentElement;
