import { Component, default as React } from "react";
import ErrorPage from "../ErrorPage/ErrorPage";
import styles from "./errorScreen.module.css";
import { ErrorScreenType } from "./model/ErrorScreenType";

export interface ErrorScreenStateProps {
    error: ErrorScreenType | null;
}

export interface ErrorScreenDetectorDispatchProps {
    showError(type: ErrorScreenType): void;

    hideError(): void;
}

export default class ErrorScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {renderError: false};
    }

    public componentDidMount(): void {
        window.addEventListener("unhandledrejection", this.errorListener);
        window.addEventListener("error", this.errorListener);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("unhandledrejection", this.errorListener);
        window.removeEventListener("error", this.errorListener);
    }

    public static getDerivedStateFromError(): State {
        return {renderError: true};
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.group("React render error");
        console.error(error);
        console.error(errorInfo);
        console.groupEnd();
        this.props.showError(ErrorScreenType.CLIENT_ERROR);
    }

    public render() {
        const {error, children} = this.props;
        const {renderError} = this.state;

        if (null === error && !renderError) {
            return children;
        }

        const reloadButton = (
            <button
                className="btn btn-primary"
                onClick={this.reload}
            >
                Uruchom aplikację ponownie
            </button>
        );

        const ignoreButton = process.env.NODE_ENV === "production" ? null : (
            <button
                className={`${styles.close} btn btn-light`}
                onClick={this.props.hideError}
            >
                &times;
            </button>
        );

        const buttons = <>{reloadButton} {ignoreButton}</>;

        switch (renderError ? ErrorScreenType.CLIENT_ERROR : error) {
            case ErrorScreenType.CLIENT_ERROR:
                return (
                    <ErrorPage
                        message="Aplikacja przestała działać"
                        description="Wystąpił nieobsługiwany wyjątek w aplikacji uniemożliwiający jej dalsze działanie."
                        button={buttons}
                    />
                );
            case ErrorScreenType.SERVER_ERROR:
                return (
                    <ErrorPage
                        message="Wystąpił bład serwera"
                        description="Wystąpił problem z serwerem aplikacji."
                        button={buttons}
                    />
                );
        }
    }

    private reload = () => document.location.reload();

    private errorListener = (event: ErrorEvent | PromiseRejectionEvent) => {
        console.error(event);
        this.props.showError(ErrorScreenType.CLIENT_ERROR);
    };
}

type Props = ErrorScreenStateProps & ErrorScreenDetectorDispatchProps;

interface State {
    renderError: boolean;
}
