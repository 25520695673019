import React, { FC } from "react";
import styles from "./loadingScreen.module.css";

const LoadingScreen: FC = () => (
    <div>
        <div className={`progress ${styles.progress}`}>
            <div className={`progress-bar bg-transparent ${styles.bar2}`}/>
            <div className={`progress-bar ${styles.bar1}`}/>
        </div>
    </div>
);

export default LoadingScreen;
