import React, {FC} from "react";

type ProblemLevelProps = {
    problemLevel: string
}

const ProblemLevel: FC<ProblemLevelProps> = ({problemLevel}) => {
    switch (problemLevel) {
        case 'PROBLEMATIC':
            return <span>bardzo duży</span>;
        case 'NORMAL':
            return <span>przeciętny</span>;
        case 'UNPROBLEMATIC':
            return <span>bardzo mały</span>;
    }

    throw new Error(`Unknown problem level: ${problemLevel}`);
};

export default ProblemLevel;
