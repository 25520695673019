import React, { FC } from "react";
import style from "./logo.module.css"
import fullLogoSet from './fullLogoSet.png'
import oreFullLogoSet from './oreFullLogoSet.png'

interface SponsorsLogoProps {
    tros?: boolean | null
}

export const SponsorsLogo: FC<SponsorsLogoProps> = ({ tros}) => (
    <div className={style.logosSection}>
        <img
            src={tros ? oreFullLogoSet : fullLogoSet}
            alt="Logotypy"
        />
    </div>

);

export default SponsorsLogo;
