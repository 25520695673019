import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { push } from "connected-react-router";
import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import MessageLayout from "../../layouts/MessageLayout/MessageLayout";
import LoadingPage from "../LoadingScreen/LoadingPage";

const REJECTION_MUTATION = gql`
    mutation RejectionExecute($token: String!) {
        result: rejectionExecute(token: $token)
    }
`;

const RejectionPage: FC<RouteComponentProps<{token: string}>> = (props) => {
    const dispatch = useDispatch();
    const [execute, {loading: executing}] = useMutation<{result: boolean}>(REJECTION_MUTATION, {
        variables: {token: props.match.params.token},
    });
    const [hasError, setHasError] = useState<boolean>(false);

    const onClick = useCallback(async () => {
        let data;
        try {
            const result = await execute();
            data = result.data;
        } catch (e) {
            setHasError(true);
            return;
        }
        if (data && data.result) {
            dispatch(push("/rejection/success"));
        }
    }, [execute, dispatch, setHasError]);

    if (executing) {
        return <LoadingPage />;
    }

    return (
        <MessageLayout title="Rezygnacja z badania">
            <button
                onClick={onClick}
                className="btn btn-primary mb-5"
            >
                Zrezygnuj z badania
            </button>
            {hasError ? (
                <div className="alert alert-danger">
                    Wystąpił błąd aplikacji.
                </div>
            ) : null}
        </MessageLayout>
    );
};

export default RejectionPage;
