import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Content from "../../../Content/Content";
import { required } from "../../../ErrorMessage/rules";
import LikertScaleButton from "../../LikertScaleButton/LikertScaleButton";
import { ItemProps } from "../LikertScaleItem";
import style from "./likertScaleBtnGroupItem.module.css"

const LikertScaleBtnGroupItem: FC<ItemProps> = (
    {
        name,
        scale,
        ordinalNumber,
        defaultValue,
        onChange,
        children,
        required: isRequired,
        editableFields,
        content
    }
) => {
    const form = useFormContext();

    const buttons = scale.descriptions.map((description, idx) => {
        const formValue = form && form.watch(name)
        const value = `${description.value}`;

        const enableFocus = (form && !form.getValues(name) && !defaultValue && !idx) // focus on first button if is none selected [WCAG]
            || defaultValue === value || (formValue && formValue === value) // focus on already selected field [WCAG]

        const textButton = <span className={ style.description }>
            <Content
                editableFields={editableFields}
                content={description.description || description.content}
            />
        </span>

        return (
            <div
                className="text-md-center col d-md-flex flex-md-column align-items-center my-md-2 mx-4 mb-1 flex-grow-1"
                key={description.id}
            >
                <LikertScaleButton
                    disabled={!form}
                    defaultChecked={defaultValue === value}
                    value={value}
                    name={name}
                    onChange={onChange}
                    tabIndex={form && enableFocus ? 0: -1}
                    isButtonGroup
                    ref={form ? form.register(isRequired ? {required} : {}) : null}
                >
                    { textButton }
                </LikertScaleButton>
            </div>
        )
    })

    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 d-flex flex-column">
                    <div className="mt-2 full-width border-top pt-3">
                        <span
                            dangerouslySetInnerHTML={{__html: ordinalNumber + "."}}
                            className="mr-1"
                        />

                        { content &&
                            <Content content={content} editableFields={editableFields}/>
                        }
                    </div>

                    <div className="row flex-grow-1 align-content-center flex-column flex-md-row justify-content-start justify-content-md-center no-gutters my-2 px-md-5">
                        { buttons }
                    </div>
                </div>
            </div>

            {/* error */}
            <div>{children}</div>
            {/* e/o error */}
        </div>
    )
}

export default LikertScaleBtnGroupItem;
