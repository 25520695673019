import { ErrorResponse } from "apollo-link-error";
import { UseFormMethods } from "react-hook-form";
import translateViolation from "./constraintViolation";

export interface ConstraintViolation {
    code: string;
    message: string;
}

const processResponseException = <Values>(
    form: UseFormMethods<Values>,
    error: ErrorResponse,
    fieldMap: Record<string, string> = {}
): void => {
    if (error.networkError || !error.graphQLErrors) {
        throw error;
    }

    const violations = error.graphQLErrors
        .filter(({extensions}) => extensions && extensions.category === 'validator')
        .map((error: any): Record<string, ConstraintViolation | undefined> => error.state)
        .reduce((violations, group) => ({
            ...group,
            ...violations,
        }), {});

    let firstInvalidFieldName: string | null = null;

    for (const fieldName in violations) {
        if (violations.hasOwnProperty(fieldName)) {
            const violation = translateViolation(violations[fieldName] as ConstraintViolation);
            const mappedFieldName = mapFieldName(fieldName, fieldMap);
            firstInvalidFieldName = firstInvalidFieldName || mappedFieldName;
            form.setError(mappedFieldName as any, {
                type: 'custom',
                message: violation.message
            })
        }
    }

    if (firstInvalidFieldName) {
        focusField(form, firstInvalidFieldName);
    }
};

const focusField = <T>(form: UseFormMethods<T>, fieldName: string) => {
    const field = form.control.fieldsRef.current[fieldName];
    try {
        field!.ref!.focus!();
    } catch (e) {
    }
}

const mapFieldName = (name: string, map: Record<string, string>): string => {
    if (map[name]) {
        return map[name];
    }

    const parts = name.split(".");

    if (1 === parts.length) {
        return name;
    }

    const right = parts.pop();

    return `${mapFieldName(parts.join("."), map)}.${right}`;
};

export default processResponseException;
