import { gql } from "apollo-boost";
import React, { FC } from "react";
import Content, { CONTENT_FRAGMENT, ContentType } from "../Content/Content";
import styles from "./anchor.module.css";

export interface AnchorProps {
    element: AnchorType;
    editableFields?: boolean;
}

const Anchor: FC<AnchorProps> = ({element, editableFields = false}) => {
    return (
        <div className="col-12">
            <div className={styles.anchor}>
                <div className="bg-dark text-light p-1">
                    <Content content={element.content} editableFields={editableFields}/>
                </div>
            </div>
        </div>
    );
};

export interface AnchorType {
    id: string;
    content: ContentType;
}

export const ANCHOR_FRAGMENT = gql`
    fragment Anchor on Anchor {
        id
        content {
            id
            ...Content
        }
    }
    ${CONTENT_FRAGMENT}
`;

export default Anchor;
