import { Reducer } from "redux";
import { saveSurveyId } from "./surveyIdActions";
import { SurveyIdTypes } from "./surveyIdTypes";

export type SurveyIdState = string | null;

const surveyIdReducer: Reducer<SurveyIdState> = (state = null, action) => {
    if (action.type === SurveyIdTypes.SAVE_SURVEY_ID) {
        const { payload } = action as ReturnType<typeof saveSurveyId>;
        return payload
    }

    return state;
};

export default surveyIdReducer
