import React, { FC, lazy, Suspense } from "react";
import { hot } from "react-hot-loader";
import { Route, Switch } from "react-router";
import LoadingPage from "../LoadingScreen/LoadingPage";
import RejectionPage from "../RejectionPage/RejectionPage";
import RejectionThanksPage from "../RejectionPage/RejectionThanksPage";
import SurveyPreviewPage from "../Survey/SurveyPreviewPage";

const ExaminationPage = lazy(() => import("../Examination/ExaminationPage"));
const ExaminationPreviewPage = lazy(() => import("../ExaminationPreview/ExaminationPreviewPage"));
const ExaminationThanksPage = lazy(() => import("../ExaminationThanks/ExaminationThanksPage"));
const ToolPreviewPage = lazy(() => import("../ToolPreview/ToolPreviewPage"));
const ToolEditPage = lazy(() => import("../ToolEdit/ToolEditPage"));
const PageNotFound = lazy(() => import("../NotFound/NotFoundPage"));
const RegistrationPage = lazy(() => import("../RegistrationPage/RegistrationPage"));
const ExaminedPersonRegistrationPage = lazy(() => import("../RegistrationPage/ExaminedPersonRegistrationPage/ExaminedPersonRegistrationPage"));
const TeacherRegistrationPage = lazy(() => import("../RegistrationPage/TeacherRegistrationPage/TeacherRegistrationPage"));
const RegistrationSuccessPage = lazy(() => import("../RegistrationSuccessPage/RegistrationSuccessPage"));
const SurveyPage = lazy(() => import("../Survey/SurveyPage"))
const SurveyThanksPage = lazy(() => import("../Survey/SurveyThanksPage"))

interface RouterProps {
    context?: {
        pilot: boolean;
    }
}

const Router: FC<RouterProps> = ({context}) => {
    const hasContext = null != context;
    const isPilot = context && context.pilot;

    const registrationRoutes = isPilot ? (
        <Route path="/registration">
            <Switch>
                <Route path="/registration" exact component={RegistrationPage}/>
                <Route path="/registration/examined-person" exact component={ExaminedPersonRegistrationPage}/>
                <Route path="/registration/teacher" exact component={TeacherRegistrationPage}/>
                <Route path="/registration/success" exact component={RegistrationSuccessPage}/>
                <Route component={PageNotFound}/>
            </Switch>
        </Route>
    ) : null;

    const examinationRoutes = hasContext ? (
        <Route path="/examination">
            <Switch>
                <Route path="/examination/:examinationId/thanks" exact component={ExaminationThanksPage}/>
                <Route path="/examination/:examinationId/:pageNumber?" exact component={ExaminationPage}/>
                <Route path="/examination/preview/:examinationId/result" exact component={ExaminationPreviewPage}/>
                <Route component={PageNotFound}/>
            </Switch>
        </Route>
    ) : null;

    const surveyRoutes = hasContext ? (
        <Route path="/survey">
            <Switch>
                <Route path="/survey/:surveyId/thanks" exact component={SurveyThanksPage}/>
                <Route path="/survey/:surveyId" exact component={SurveyPage}/>
                <Route path="/survey/preview/:surveyId/result" exact component={SurveyPreviewPage}/>
            </Switch>
        </Route>
    ) : null;

    const rejectionRoutes = hasContext ? (
        <Route path="/rejection">
            <Switch>
                <Route path="/rejection/success" exact component={RejectionThanksPage} />
                <Route path="/rejection/:token" exact component={RejectionPage} />
                <Route component={PageNotFound}/>
            </Switch>
        </Route>
    ) : null;

    return (
        <Suspense fallback={<LoadingPage />}>
            <Switch>
                {registrationRoutes}
                {examinationRoutes}
                {rejectionRoutes}
                {surveyRoutes}
                <Route path="/tool/:code/:version" exact component={ToolPreviewPage} />
                <Route path="/tool/:code/:version/edit/:id/examination" exact component={ToolEditPage} />
                <Route component={PageNotFound}/>
            </Switch>
        </Suspense>
    );
};

export default hot(module)(Router);
