export const instructionTranslations = {
  instructionHeaderNextPages: {
    PL: "Na kolejnych stronach znajdziesz opisy różnych sytuacji.",
    EN: "Na kolejnych stronach znajdziesz opisy różnych sytuacji.",
    YК: "На наступних сторінках знайдеш описи різних ситуацій.",
    РYC: "На следующих страницах найдешь описания различных ситуаций."
  },
  instructionHeaderHowDoYouBehave: {
    PL: "Zastanów się, jak Ty się zachowujesz, co czujesz i myślisz w takich sytuacjach.",
    EN: "Zastanów się, jak Ty się zachowujesz, co czujesz i myślisz w takich sytuacjach.",
    YК: "Поміркуй, як Ти поводишся, що відчуваєш та думаєш в таких ситуаціях.",
    РYC: "Подумай, как Ты себя ведешь, что чувствуешь и думаешь в таких ситуациях."
  },
  instructionHeaderHowChildBehave: {
    PL: "Na kolejnych stronach znajdziesz opisy różnych sytuacji oraz dwóch możliwych reakcji dziecka. Zastanów się, jak w takich sytuacjach zwykle zachowuje się",
    EN: "Na kolejnych stronach znajdziesz opisy różnych sytuacji oraz dwóch możliwych reakcji dziecka. Zastanów się, jak w takich sytuacjach zwykle zachowuje się",
    YК: "На наступних сторінках Ви знайдете опис різних ситуацій та дві можливі реакції дитини. Поміркуйте, як зазвичай в таких ситуаціях поводиться",
    РYC: "На следующих страницах Вы найдете описание различных ситуаций и две возможные реакции ребенка. Подумайте, как в таких ситуациях ведет себя"
  },
  instructionHeaderYourChild: {
    PL: "Twoje dziecko",
    EN: "Twoje dziecko",
    YК: "Ваша дитина",
    РYC: "Ваш ребенок"
  },
  instructionHeaderThinksFeelsOrSpeaks: {
    PL: "co wtedy myśli, czuje lub mówi.",
    EN: "co wtedy myśli, czuje lub mówi.",
    YК: " що тоді думає, відчуває або говорить.",
    РYC: " что тогда думает, чувствует или говорит."
  },
  instructionHeaderDoesOrFeels: {
    PL: "co wtedy robi lub odczuwa.",
    EN: "co wtedy robi lub odczuwa.",
    YК: "що тоді робить або відчуває.",
    РYC: "что тогда делает или чувствует в это время."
  },
  instructionHeaderDecideReactionFromTwoDescriptions: {
    PL: "Najpierw zdecyduj, który z dwóch opisów reakcji bardziej do",
    EN: "Najpierw zdecyduj, który z dwóch opisów reakcji bardziej do",
    YК: "Спочатку вирішіть, який із двох описів поведінки їй/йому ",
    РYC: "Сначала решите, какое из двух описаний поведения ей/ему "
  },
  instructionHeaderFitLeftOrRight: {
    PL: "pasuje – ten po lewej czy ten po prawej stronie.",
    EN: "pasuje – ten po lewej czy ten po prawej stronie.",
    YК: "підходить найбільше - той, що ліворуч, чи той, що праворуч.",
    РYC: "подходит больше всего - то, что слева, или то, что справа."
  },
  instructionHeaderDecideFitFromTwoDescriptions: {
    PL: "Następnie zdecyduj, czy wybrany opis pasuje do",
    EN: "Następnie zdecyduj, czy wybrany opis pasuje do",
    YК: "Потім вирішіть, чи відповідає вибраний Вами опис їй/йому ",
    РYC: "Затем решите, соответствует ли выбранное Вами описание ей/ему "
  },
  instructionHeaderChooseOption: {
    PL: "bardzo, czy tylko trochę. Jeśli pasuje bardzo, zaznacz odpowiedź „zdecydowanie tak” umieszczoną pod odpowiednim opisem. Jeśli pasuje tylko trochę, pod wybranym opisem zaznacz odpowiedź „raczej tak”.",
    EN: "bardzo, czy tylko trochę. Jeśli pasuje bardzo, zaznacz odpowiedź „zdecydowanie tak” umieszczoną pod odpowiednim opisem. Jeśli pasuje tylko trochę, pod wybranym opisem zaznacz odpowiedź „raczej tak”.",
    YК: 'дуже добре, або лише трохи. Якщо підходить дуже добре, зазначте відповідь "однозначно так" під відповідним описом. Якщо підходить тільки трохи, зазначте "швидше так" під вибраним Вами описом.',
    РYC: 'очень хорошо или только немного. Если оно очень хорошо подходит, отметьте ответ "определенно да" под соответствующим описанием. Если оно подходит только немного, отметьте ответ "скорее да" под выбранным Вами описанием.'
  },
  instructionHeaderVariedBehavior: {
    PL: "Jeśli zachowania opisywanego dziecka w danej sytuacji są zróżnicowane lub żaden z opisów nie charakteryzuje",
    EN: "Jeśli zachowania opisywanego dziecka w danej sytuacji są zróżnicowane lub żaden z opisów nie charakteryzuje",
    YК: 'Якщо поведінка дитини у цій ситуації різна чи жоден з описів не характеризує її точніше, ніж інший, зазначте відповідь "по-різному" – але намагайтеся вибирати цей варіант якомога рідше.',
    РYC: 'Если поведение описываемого ребенка в данной ситуации разное или ни одно из описаний не характеризует его более точно, чем остальные, отметьте ответ "по-разному" – но старайтесь выбирать этот вариант как можно реже.'
  },
  instructionHeaderVariedBehavior2: {
    PL: "trafniej niż pozostały, zaznacz odpowiedź „różnie” – staraj się jednak wybierać tę opcję możliwie rzadko.",
    EN: "trafniej niż pozostały, zaznacz odpowiedź „różnie” – staraj się jednak wybierać tę opcję możliwie rzadko.",
    YК: "",
    РYC: ""
  },
  instructionHeaderNoCorrectAnswer: {
    PL: "Nie ma tu dobrych ani złych odpowiedzi, bo każde dziecko jest inne i inaczej się zachowuje. Dlatego proszę udzielać odpowiedzi szczerych oraz zgodnych ze swoimi obserwacjami i wiedzą na temat opisywanego dziecka. Wybraną odpowiedź zaznacz, klikając na jedno z okienek znajdujących się pod opisami reakcji.",
    EN: "Nie ma tu dobrych ani złych odpowiedzi, bo każde dziecko jest inne i inaczej się zachowuje. Dlatego proszę udzielać odpowiedzi szczerych oraz zgodnych ze swoimi obserwacjami i wiedzą na temat opisywanego dziecka. Wybraną odpowiedź zaznacz, klikając na jedno z okienek znajdujących się pod opisami reakcji.",
    YК: "Тут немає правильних чи неправильних відповідей, адже кожна дитина індивідуальна і поводиться по-різному. Тому, будь ласка, відповідайте чесно і відповідно до Ваших спостережень і знань про досліджувану дитину. Зазначте вибрану відповідь, натиснувши на одне з віконець під описом поведінки.",
    РYC: "Здесь нет правильных или неправильных ответов, потому что каждый ребенок индивидуален и ведет себя по-разному. Поэтому, пожалуйста, отвечайте честно и в соответствии с Вашими наблюдениями и знаниями об исследуемом ребенке. Отметьте выбранный ответ, нажав на одно из окошек под описанием поведения."
  },
  instructionHeaderPreferToPlayWithToys: {
    PL: "Jeśli opisywane dziecko w sytuacji, kiedy może wybrać, gdzie chce się bawić, woli bawić się zabawkami w domu",
    EN: "Jeśli opisywane dziecko w sytuacji, kiedy może wybrać, gdzie chce się bawić, woli bawić się zabawkami w domu",
    YК: 'Якщо досліджувана дитина у ситуації, коли може вибрати, де бажає гратися, віддає перевагу іграм з іграшками вдома, будь ласка, зазначте одну з відповідей, наведених під описом зліва. Якщо можна вважати, що цей опис дуже точно відображає типову поведінку дитини в даній ситуації, зазначте відповідь "однозначно так", як наведено вище.',
    РYC: 'Если исследуемый ребенок в ситуации, когда может выбрать, где хочет играть, предпочитает играть с игрушками дома, пожалуйста, отметьте один из ответов, приведенных под описанием слева. Если можно считать, что это описание очень точно отражает типичное поведение ребенка в данной ситуации, пожалуйста, отметьте ответ "определенно да" так, как указано выше.'
  },
  instructionHeaderPreferToPlayWithToysInClassroom: {
    PL: "Jeśli opisywane dziecko w sytuacji, kiedy może wybrać, gdzie chce się bawić, woli bawić się zabawkami w sali",
    EN: "Jeśli opisywane dziecko w sytuacji, kiedy może wybrać, gdzie chce się bawić, woli bawić się zabawkami w sali",
    YК: 'Якщо досліджувана дитина у ситуації, коли може вибрати, де бажає гратися, віддає перевагу іграм з іграшками в класі, будь ласка, зазначте одну з відповідей, наведених під описом зліва. Якщо можна вважати, що цей опис дуже точно відображає типову поведінку дитини в даній ситуації, зазначте відповідь "однозначно так", як наведено вище.',
    РYC: 'Если исследуемый ребенок в ситуации, когда может выбрать, где хочет играть, предпочитает играть с игрушками в классе, пожалуйста, отметьте один из ответов, приведенных под описанием слева. Если можно считать, что это описание очень точно отражает типичное поведение ребенка в данной ситуации, пожалуйста, отметьте ответ "определенно да" так, как указано выше.'
  },
  instructionHeaderPreferToPlayWithToysInClassroomOrAtHome: {
    PL: "Jeśli opisywane dziecko w sytuacji, kiedy może wybrać, gdzie chce się bawić, woli bawić się zabawkami w domu lub sali",
    EN: "Jeśli opisywane dziecko w sytuacji, kiedy może wybrać, gdzie chce się bawić, woli bawić się zabawkami w domu lub sali",
    YК: 'Якщо досліджувана дитина у ситуації, коли може вибрати, де бажає гратися, віддає перевагу іграм з іграшками вдома або в класі, будь ласка, зазначте одну з відповідей, наведених під описом зліва. Якщо можна вважати, що цей опис дуже точно відображає типову поведінку дитини в даній ситуації, зазначте відповідь "однозначно так", як наведено вище.',
    РYC: 'Если исследуемый ребенок в ситуации, когда может выбрать, где хочет играть, предпочитает играть с игрушками дома или в классе, пожалуйста, отметьте один из ответов, приведенных под описанием слева. Если можно считать, что это описание очень точно отражает типичное поведение ребенка в данной ситуации, пожалуйста, отметьте ответ "определенно да" так, как указано выше.'
  },
  instructionHeaderPreferToPlayWithToys2: {
    PL: " należy zaznaczyć jedną z odpowiedzi znajdujących się pod opisem po lewej stronie. Jeżeli można uznać, że ten opis bardzo trafnie obrazuje typowe zachowania dziecka w tej sytuacji, należy zaznaczyć odpowiedź „zdecydowanie tak”, w taki sposób, jak zaprezentowano powyżej.",
    EN: " należy zaznaczyć jedną z odpowiedzi znajdujących się pod opisem po lewej stronie. Jeżeli można uznać, że ten opis bardzo trafnie obrazuje typowe zachowania dziecka w tej sytuacji, należy zaznaczyć odpowiedź „zdecydowanie tak”, w taki sposób, jak zaprezentowano powyżej.",
    YК: "",
    РYC: ""
  },
  instructionHeaderPreferToPlayWithSoftToys: {
    PL: "Jeśli opisywane dziecko w sytuacji, kiedy może bawić się dowolnymi zabawkami, woli zabawki miękkie, należy zaznaczyć jedną z odpowiedzi znajdujących się pod opisem po lewej stronie. Jeżeli można uznać, że ten opis bardzo trafnie obrazuje typowe zachowania dziecka w tej sytuacji, należy zaznaczyć odpowiedź „zdecydowanie tak”, w taki sposób, jak zaprezentowano powyżej.",
    EN: "Jeśli opisywane dziecko w sytuacji, kiedy może bawić się dowolnymi zabawkami, woli zabawki miękkie, należy zaznaczyć jedną z odpowiedzi znajdujących się pod opisem po lewej stronie. Jeżeli można uznać, że ten opis bardzo trafnie obrazuje typowe zachowania dziecka w tej sytuacji, należy zaznaczyć odpowiedź „zdecydowanie tak”, w taki sposób, jak zaprezentowano powyżej.",
    YК: 'Якщо досліджувана дитина у ситуації,  коли може вибрати, де хоче гратиcя, вважає за краще гратися в кімнаті або класі, будь ласка, зазначте одну з відповідей, наведених під описом зліва. Якщо можна вважати, що цей опис дуже точно відображає типову поведінку дитини в даній ситуації, зазначте відповідь "однозначно так", як наведено вище.',
    РYC: 'Если исследуемый ребенок предпочитает мягкие игрушки, когда может играть с любыми, пожалуйста, отметьте один из ответов, приведенных под описанием слева. Если можно считать, что это описание очень точно отражает типичное поведение ребенка в данной ситуации, пожалуйста, отметьте ответ "определенно да" так, как указано выше.'
  },
  instructionHeaderRightDescription: {
    PL: "Jeśli jednak w tej sytuacji zachowanie dziecka trochę trafniej określa opis po prawej stronie, ale nie jest to dla",
    EN: "Jeśli jednak w tej sytuacji zachowanie dziecka trochę trafniej określa opis po prawej stronie, ale nie jest to dla",
    YК: 'Якщо, однак, у цій ситуації поведінка дитини трохи точніше відповідає опису справа, але не дуже характерна для неї/нього',
    РYC: 'Если, однако, в этой ситуации поведение ребенка несколько точнее соответствует описанию справа, но не очень характерно для нее/него'
  },
  instructionHeaderRightDescription2: {
    PL: "bardzo charakterystyczne, należy zaznaczyć znajdującą się pod tym opisem odpowiedź „raczej tak”, tak jak poniżej.",
    EN: "bardzo charakterystyczne, należy zaznaczyć znajdującą się pod tym opisem odpowiedź „raczej tak”, tak jak poniżej.",
    YК: ' належить зазначити під описом відповідь "швидше так", як показано нижче.',
    РYC: ' следует отметить под описанием ответ "скорее да", как показано ниже.'
  },
  instructionHeaderHer: {
    PL: " niej ",
    EN: " niej ",
    YК: " їй ",
    РYC: " ей "
  },
  instructionHeaderHim: {
    PL: " niego ",
    EN: " niego ",
    YК: " йому ",
    РYC: " ему "
  },
  instructionHeaderBoyDifferentBehaviors: {
    PL: "Aby Ci to ułatwić – po prawej i lewej stronie są przedstawione różne zachowania tego samego Bohatera.",
    EN: "Aby Ci to ułatwić – po prawej i lewej stronie są przedstawione różne zachowania tego samego Bohatera.",
    YК: "Щоб Тобі було легше - праворуч і ліворуч представлені різні варіанти поведінки одного і того ж Героя.",
    РYC: "Чтобы Тебе было проще – справа и слева представлены разные варианты поведения одного и того же Героя."
  },
  instructionHeaderGirlDifferentBehaviors: {
    PL: "Aby Ci to ułatwić – po prawej i lewej stronie są przedstawione różne zachowania tej samej Bohaterki.",
    EN: "Aby Ci to ułatwić – po prawej i lewej stronie są przedstawione różne zachowania tej samej Bohaterki.",
    YК: "Щоб Тобі було легше - праворуч і ліворуч представлені різні варіанти поведінки однієї і тієї ж Героїні.",
    РYC: "Чтобы Тебе было проще – справа и слева представлены разные варианты поведения одной и той же Героини."
  },
  instructionHeaderBoyOutfit: {
    PL: "Jest on zawsze ubrany w koszulkę w paski.",
    EN: "Jest on zawsze ubrany w koszulkę w paski.",
    YК: "Він завжди в смугастій футболці.",
    РYC: "Он всегда в полосатой футболке."
  },
  instructionHeaderGirlOutfit: {
    PL: "Jest ona zawsze ubrana w koszulkę w paski.",
    EN: "Jest ona zawsze ubrana w koszulkę w paski.",
    YК: "Вона завжди в смугастій футболці.",
    РYC: "Она всегда в полосатой футболке."
  },
  instructionHeaderHereHeIs: {
    PL: "Oto on:",
    EN: "Oto on:",
    YК: "Ось він:",
    РYC: "Вот он:"
  },
  instructionHeaderHereSheIs: {
    PL: "Oto ona:",
    EN: "Oto ona:",
    YК: "Ось вона:",
    РYC: "Вот она:"
  },
  instructionHeaderSuitableForYou: {
    PL: "Zastanów się – czy bardziej pasuje do Ciebie to",
    EN: "Zastanów się – czy bardziej pasuje do Ciebie to",
    YК: "Поміркуй - Тобі більше підходить те",
    РYC: "Подумай, Тебе больше подходит то"
  },
  instructionHeaderWhatBoyDo: {
    PL: ", co robi Bohater",
    EN: ", co robi Bohater",
    YК: ", що Герой робить",
    РYC: ", что Герой делает"
  },
  instructionHeaderWhatGirlDo: {
    PL: ", co robi Bohaterka",
    EN: ", co robi Bohaterka",
    YК: ", що Героїня робить",
    РYC: ", что Героиня делает"
  },
  instructionHeaderLeftOrRight: {
    PL: "po prawej stronie, czy po lewej stronie.",
    EN: "po prawej stronie, czy po lewej stronie.",
    YК: "праворуч чи ліворуч.",
    РYC: "справа или слева."
  },
  instructionHeaderUpOrDown: {
    PL: "na górze, czy na dole?",
    EN: "na górze, czy na dole?",
    YК: "вверху чи внизу?",
    РYC: "вверху или внизу"
  },
  instructionHeaderFitALotOrALittle: {
    PL: "Czy dany opis pasuje do Ciebie bardzo, czy tylko trochę?",
    EN: "Czy dany opis pasuje do Ciebie bardzo, czy tylko trochę?",
    YК: "Чи даний опис підходить Тобі дуже добре, чи лише трохи?",
    РYC: "Данное описание подходит Тебе очень хорошо или только немного?"
  },
  example: {
    PL: "Przykład",
    EN: "Przykład",
    YК: "ПРИКЛАД",
    РYC: "ПРИМЕР"
  },
  questionChoseWhereToPlay: {
    PL: "Kiedy może wybrać, gdzie chce się bawić...",
    EN: "Kiedy może wybrać, gdzie chce się bawić...",
    YК: "Коли може вибрати, де хоче гратися…",
    РYC: "Когда может выбрать, где хочет играть..."
  },
  questionPlayWithAnyToys: {
    PL: "Kiedy może bawić się dowolnymi zabawkami…",
    EN: "Kiedy może bawić się dowolnymi zabawkami…",
    YК: "Коли може гратися з будь-якими іграшками...",
    РYC: "Когда может играть с любыми игрушками..."
  },
  questionWhenICanDoWhatIWant: {
    PL: "Kiedy mogę robić, co chcę...",
    EN: "Kiedy mogę robić, co chcę...",
    YК: "Коли можу робити те, що хочу…",
    РYC: "Когда могу делать то, что хочу…"
  },
  questionToys: {
    PL: "... woli bawić się zabawkami w domu",
    EN: "... woli bawić się zabawkami w domu",
    YК: "… бажає гратися з іграшками вдома",
    РYC: "… предпочитает играть с игрушками дома"
  },
  questionClassroomToys: {
    PL: "... woli bawić się zabawkami w sali",
    EN: "... woli bawić się zabawkami w sali",
    YК: "… бажає гратися з іграшками в класі",
    РYC: "… предпочитает играть с игрушками в классе"
  },
  questionClassroomOrAtHomeToys: {
    PL: "... woli bawić się zabawkami w domu lub sali",
    EN: "... woli bawić się zabawkami w domu lub sali",
    YК: "… бажає гратися з іграшками вдома або в класі",
    РYC: "… предпочитает играть с игрушками дома или в классе"
  },
  questionSoftToys: {
    PL: "… woli zabawki miękkie, do których można się przytulać, np. pluszaki",
    EN: "… woli zabawki miękkie, do których można się przytulać, np. pluszaki",
    YК: "… віддає перевагу м’яким іграшкам, до яких може притулитися, наприклад,плюшеві іграшки",
    РYC: "… предпочитает мягкие игрушки, к которым может прижаться, например,плюшевые игрушки"
  },
  questionGlowingToys: {
    PL: "… woli zabawki, które się świecą lub wydają dźwięki, np. grzechotki",
    EN: "… woli zabawki, które się świecą lub wydają dźwięki, np. grzechotki",
    YК: "… віддає перевагу іграшкам, які світятьсяабо видають звуки, наприклад,брязкальця",
    РYC: "… предпочитает светящиеся или издающие звуки игрушки, например, погремушки"
  },
  questionOutside: {
    PL: "... woli bawić się w coś na podwórku",
    EN: "... woli bawić się w coś na podwórku",
    YК: "… бажає гратися у щось на подвір’ї",
    РYC: "… предпочитает играть во что-то во дворе"
  },
  questionOr: {
    PL: "czy",
    EN: "czy",
    YК: "чи",
    РYC: "или"
  },
  questionToysAtHome: {
    PL: "... wolę bawić się zabawkami w domu",
    EN: "... wolę bawić się zabawkami w domu",
    YК: "… віддаю перевагу іграм з іграшками вдома",
    РYC: "… предпочитаю играть с игрушками дома"
  },
  questionOutsideHouse: {
    PL: "... wolę bawić się w coś poza domem",
    EN: "... wolę bawić się w coś poza domem",
    YК: "… віддаю перевагу іграм у щось поза домом",
    РYC: "… предпочитаю играть во что-то вне дома"
  },
  answerDefinitelyYes: {
    PL: "zdecydowanie tak",
    EN: "zdecydowanie tak",
    YК: "однозначно так",
    РYC: "определенно да"
  },
  answerYes: {
    PL: "tak!",
    EN: "tak!",
    YК: "так!",
    РYC: "да!"
  },
  answerRatherYes: {
    PL: "raczej tak",
    EN: "raczej tak",
    YК: "швидше так",
    РYC: "скорее да"
  },
  answerDifferently: {
    PL: "różnie",
    EN: "różnie",
    YК: "по-різному",
    РYC: "по-разному"
  },
  explanationAtHome: {
    PL: "Jeśli w takiej sytuacji bardziej lubisz bawić się w domu, zaznacz przycisk",
    EN: "Jeśli w takiej sytuacji bardziej lubisz bawić się w domu, zaznacz przycisk",
    YК: "Якщо в цій ситуації Тобі більше подобається гратися вдома, натисни кнопку",
    РYC: "Если в данной ситуации Тебе больше нравится играть дома, нажми кнопку"
  },
  explanationOutdoor: {
    PL: "Jeśli w takiej sytuacji bardziej lubisz bawić się poza domem, zaznacz przycisk",
    EN: "Jeśli w takiej sytuacji bardziej lubisz bawić się poza domem, zaznacz przycisk",
    YК: "Якщо в цій ситуації Тобі більше подобається гратися поза домом, натисни кнопку",
    РYC: "Если в данной ситуации Тебе больше нравится играть вне дома, нажми кнопку"
  },
  explanationSometimesOutdoor: {
    PL: "Jeśli czasem wolisz zabawę w domu, a czasem poza domem – zaznacz",
    EN: "Jeśli czasem wolisz zabawę w domu, a czasem poza domem – zaznacz",
    YК: "Якщо Ти іноді хочеш гратися вдома, а іноді поза домом - зазнач",
    РYC: "Если Ты иногда предпочитаешь играть дома, а иногда вне дома - отметь"
  },
  explanationLittleOutdoor: {
    PL: "- jeśli tylko trochę bardziej wolisz zabawę poza domem",
    EN: "- jeśli tylko trochę bardziej wolisz zabawę poza domem",
    YК: "– якщо Тобі лише трохи подобається гратися поза домом",
    РYC: "– если Тебе лишь немного нравится играть вне дома"
  },
  explanationDefinitelyOutdoor: {
    PL: "- jeśli zdecydowanie bardziej wolisz zabawę poza domem",
    EN: "- jeśli zdecydowanie bardziej wolisz zabawę poza domem",
    YК: "– якщо Тобі однозначно більше подобається гратися поза домом",
    РYC: "– если Тебе определенно больше нравится играть вне дома"
  },
  explanationDefinitelyAtHome: {
    PL: "- jeśli zdecydowanie bardziej wolisz zabawę w domu",
    EN: "- jeśli zdecydowanie bardziej wolisz zabawę w domu",
    YК: "- якщо Тобі однозначно більше подобається гратися вдома",
    РYC: "- если Тебе определенно больше нравится играть дома"
  },
  explanationLittleAtHome: {
    PL: "- jeśli tylko trochę bardziej wolisz zabawę w domu",
    EN: "- jeśli tylko trochę bardziej wolisz zabawę w domu",
    YК: "- якщо Тобі лише трохи подобається гратися вдома",
    РYC: "- если Тебе лишь немного нравится играть дома"
  },
  explanationTryToAvoid: {
    PL: ", ale staraj się unikać tej odpowiedzi.",
    EN: ", ale staraj się unikać tej odpowiedzi.",
    YК: ", але намагайся вибирати цей варіант якомога рідше.",
    РYC: ", но старайся выбирать этот вариант как можно реже."
  }
}
