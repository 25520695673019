import { Reducer } from "react";
import { Action } from "redux";
import { setLang } from "./langActions";
import { LangTypes } from "./langTypes";

export enum LangState {
    PL = "PL",
    UK = "YК",
    RUS = "РYC",
    EN = "EN",
}

export const langReducer: Reducer<LangState, Action<LangTypes>> = (state = LangState.PL, action) => {

    if (action.type === LangTypes.SET) {
        const {payload} = action as ReturnType<typeof setLang>

        return payload
    }

    return state
}
