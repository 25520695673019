import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { call, takeEvery } from "redux-saga/effects";
import { setContext } from "../../fetch";

function *contextSaga () {
    yield takeEvery(LOCATION_CHANGE, locationChangeSaga)
}

function *locationChangeSaga({payload}: LocationChangeAction) {
    const {search} = payload.location;
    const match = search.match(/^\?context=(?<context>(\w|-|_)+)$/);
    const {context} = (match ? match.groups : {}) as Record<string, string>;

    yield call(setContext, context);
}

export default contextSaga;
