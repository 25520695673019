import React, {FC} from 'react'
import {SurveyElementTypeEnum, SurveyElementType, SurveyAnswerType} from "../Survey/Survey.types";
import TextQuestion from "../TextQuestion/TextQuestion";
import SurveyLikertElement from "./SurveyLikertElement";

interface SurveyElementProps {
    element: SurveyElementType;
    answers: SurveyAnswerType[];
}

const SurveyElement: FC<SurveyElementProps> = ({element, answers}) => {
    switch (element.type) {
        case SurveyElementTypeEnum.TEXT_QUESTION:
            return <div>
                { element.questions.map((question) => {
                    const answerItem = answers.find(answer => answer.code === question.code)

                    return (
                        <TextQuestion
                            key={question.code}
                            element={{
                                id: question.text,
                                content: {
                                    __typename: "TextContent",
                                    id: question.text,
                                    text: question.text,
                                },
                                ordinalNumber: question.ordinalNumber + ".",
                                code: question.code,
                                answer: answerItem ? answerItem.value : undefined,
                                required: true,
                            }}
                            editableFields
                        />
                    )
                })}
            </div>;
        case SurveyElementTypeEnum.LIKERT:
            return (
                <SurveyLikertElement
                    element={element}
                    answers={answers}
                />
            )
    }

    throw new Error(`Unknown survey element type: ${element.type}`);
}

export default SurveyElement

