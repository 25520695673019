export enum SurveyElementTypeEnum {
    TEXT_QUESTION = "TEXT_QUESTION",
    LIKERT = "LIKERT"
}

export enum SurveyStatusEnum {
    OPENED = "OPENED",
    CLOSED = "CLOSED"
}

export type SurveyElementType = {
    ordinalNumber: number,
    type: SurveyElementTypeEnum,
    scale: {
        question: string,
        descriptions: Array<SurveyElementScaleDescription>
    },
    questions: Array<{
        ordinalNumber: number,
        text: string,
        code: string
    }>
}

export type SurveyElementScaleDescription = {
    value: string,
    text: string,
}

export type SurveyAnswerType = {
    code: string,
    value: string
}

export type SurveyType = {
    surveyById: {
        id: string,
        tool: {
            id: string,
            name: string,
            description: string,
            creationDate: string,
            elements: SurveyElementType[],
        },
        answers: SurveyAnswerType[],
        status: SurveyStatusEnum,
        creationDate: string,
        closedAt: string
    }
}