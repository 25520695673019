import { PendingExaminationAnswersTypes } from "./pendingExaminationAnswersTypes"
import { AnswerItemType, PendingExaminationAnswers } from "./pendingExaminationAnswersReducer";

export const examinationAnswer = (payload: AnswerItemType) => ({
    type: PendingExaminationAnswersTypes.EXAMINATION_ANSWER,
    payload,
});

export const examinationAnswerSaved = (payload: PendingExaminationAnswers) => ({
    type: PendingExaminationAnswersTypes.EXAMINATION_ANSWER_SAVED,
    payload,
});