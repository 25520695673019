import { Reducer } from "redux";
import { saveExaminationName } from "./examinationNameActions";
import { ExaminationNameTypes } from "./examinationNameTypes";

export type ExaminationNameState = string | null;

const examinationNameReducer: Reducer<ExaminationNameState> = (state = null, action) => {
    if (action.type === ExaminationNameTypes.SAVE_EXAMINATION_NAME) {
        const { payload } = action as ReturnType<typeof saveExaminationName>;
        return payload
    }

    return state;
};

export default examinationNameReducer
