import { store } from "./index";
import { checkUpdate } from "./store/version/versionActions";

const headers: Record<string, string> = {};

const clientFetch: WindowOrWorkerGlobalScope["fetch"] = async (uri, options: RequestInit) => {
    const response = await fetch(uri, {
        ...options,
        headers: {
            ...options.headers,
            ...headers,
        },
    });

    if (
        response.headers.has(versionHeader) &&
        response.headers.get(versionHeader) !== process.env.REACT_APP_VERSION
    ) {
        store.dispatch(checkUpdate(true));
    }

    return response;
};

export const setContext = (context: string|null) => {
    const headerName = "X-Tenant";
    if (!context) delete headers[headerName];
    else headers[headerName] = context;
}

const versionHeader = 'X-App-Version';

export default clientFetch;
