import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveSurveyId } from "../store/surveyId/surveyIdActions";

const useSurvey = (surveyId: string | null) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(saveSurveyId(surveyId));
    }, [dispatch, surveyId]);

    useEffect(() => () => {
        dispatch(saveSurveyId(null));
    }, [dispatch]);
};

export default useSurvey;
