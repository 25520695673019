import { Reducer} from "redux";
import { saveIsTrosExamination } from './trosExaminationActions'
import { TrosExaminationTypes } from "./trosExaminationTypes";

export type IsTrosExamination = boolean | null;

const trosExaminationReducer: Reducer<IsTrosExamination> = (state = null, action) => {
    if(action.type === TrosExaminationTypes.IS_TROS_EXAMINATION) {
        const { payload } = action as ReturnType<typeof saveIsTrosExamination>;
        return payload
    }

    return state;
}

export default trosExaminationReducer