import React, { forwardRef, ForwardRefRenderFunction, ReactElement, Ref } from "react";
import styles from "./selectQuestionButton.module.css";
import { useFormContext } from "react-hook-form";
import {useSelector} from "react-redux";
import {wcagSelector} from "../../../store/wcag/wcagSelectors";
import {WcagState} from "../../../store/wcag/wcagReducer";

interface SelectQuestionButtonProps {
    value: number;
    displayValue?: ReactElement;
    children?: ReactElement;
    onChange: (value: number) => void;
    defaultChecked: boolean;
    code: string;
    disabled: boolean;
    multi: boolean;
    tabIndex: number;
    presentationType: 'CHILD' | 'CHILD_STRUCTURED' | 'COMPACT' | 'COLUMN';
}

const SelectQuestionButton: ForwardRefRenderFunction<HTMLInputElement, SelectQuestionButtonProps> = (
    {
        value,
        displayValue,
        children,
        onChange,
        defaultChecked,
        code,
        disabled,
        multi,
        tabIndex,
        presentationType
    },
        ref: Ref<HTMLInputElement>
) => {
    const form = useFormContext()
    const highContrast = useSelector(wcagSelector) === WcagState.CONTRAST

    const isChildType: boolean = ["CHILD_STRUCTURED", "CHILD"].includes(presentationType)
    const isChildStructuredType: boolean = presentationType === "CHILD_STRUCTURED"

    const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        // if only on real mouse click - pass by on radio input arrow navigation [WCAG]
        if (event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
            (event.target as HTMLInputElement).blur()
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => { // [WCAG]
        if (event.key === 'Enter' && code) {
            event.preventDefault()
            onChange && onChange(value)
            form && form.setValue(code, value)
        }
    }

    return (
        <>
            <label>
                <input
                    disabled={disabled}
                    className={`sr-only ${styles.input}`}
                    type={multi ? "checkbox" : "radio"}
                    name={code}
                    value={value}
                    defaultChecked={defaultChecked}
                    tabIndex={tabIndex}
                    onClick={onClick}
                    onChange={() => onChange(value)}
                    onKeyDown={onKeyDown}
                    ref={ref}
                />
                <div
                    className={`
                        btn btn-outline-dark
                        ${ styles.button }
                        ${ isChildType ? "rounded-circle" : styles.compactButton }
                        ${ isChildStructuredType ? styles.childStructuredButton : "" }
                        ${ highContrast ? styles.heightContrastButton : "" }
                        ${ disabled ? "disabled" : "" }
                    `}
                >
                    { isChildType
                        ? (displayValue || value)
                        : highContrast ? children : ""
                    }
                </div>
            </label>

            <div>
                { (highContrast && !isChildType) ? null : children }
            </div>
        </>
    );
};

export default forwardRef(SelectQuestionButton);
