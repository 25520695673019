import * as React from "react";
import { CSSProperties, FC, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import styles from "./blankLayout.module.css";
import {useSelector} from "react-redux";
import {wcagSelector} from "../../store/wcag/wcagSelectors";
import {WcagState} from "../../store/wcag/wcagReducer";

interface BlankLayoutProps {
    title: string;
    withoutHelmet?: boolean;
    maxWidth?: number;
}

const BlankLayout: FC<BlankLayoutProps> = (props) => {
    const highContrast = useSelector(wcagSelector) === WcagState.CONTRAST

    const contentStyle = useMemo((): CSSProperties => ({
        maxWidth: props.maxWidth
    }), [props.maxWidth]);

    return (
        <div className={`container ${styles.layout} d-flex flex-column justify-content-between ${highContrast ? styles.highContrast : ""}`}>
            {!props.withoutHelmet ? (
                <Helmet>
                    <title>{props.title}</title>
                </Helmet>
            ) : null}
            <main style={contentStyle} className={styles.content}>
                {props.children}
            </main>
            <footer className={`row small text-muted pb-3 ${styles.footer}`}>
                <div className="col-sm">
                    &copy;{new Date().getFullYear()}
                </div>
                <div className="col-sm text-right">
                    {process.env.REACT_APP_VERSION}
                </div>
            </footer>
        </div>
    );
};

export default BlankLayout;
