import { fork } from "redux-saga/effects";
import contextSaga from "./context/contextSaga";
import pageSaga from "./page/pageSaga";
import versionSaga from "./version/versionSaga";
import pendingExaminationAnswersSaga from "./pendingExaminationAnswers/pendingExaminationAnswersSaga"

export default function* () {
    yield fork(contextSaga);
    yield fork(pageSaga);
    yield fork(versionSaga);
    yield fork(pendingExaminationAnswersSaga)
}
