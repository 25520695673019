import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Content, { ContentType } from "../../Content/Content";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { required as requiredRule } from "../../ErrorMessage/rules";
import { ScaleItem, SelectComponentQuestionProps } from "../SelectQuestion";
import SelectQuestionButton from "../SelectQuestionButton/SelectQuestionButton";
import style from "./selectChildStructuredQuestion.module.css";

const SelectStructuredQuestion: FC<SelectComponentQuestionProps> = (
    {
        element: {
            code,
            answer,
            required,
            multi,
            ordinalNumber,
            content,
            scale: {descriptions},
        },
        onChange,
        editableFields = false
    }
) => {
    const form = useFormContext();

    const defaultValue = null == answer ? undefined : JSON.parse(answer) as number | number[];
    const groups = descriptions.filter((item) => null == item.value);
    const items = descriptions.filter((item) => null != item.value);

    const isInGroup = (item: ScaleItem) => (group: ScaleItem) => {
        return item.value >= group.rangeFrom && item.value <= group.rangeTo;
    };
    const itemsWithoutGroup = items.filter((item) => !groups.find(isInGroup(item)));
    const roots = [...groups, ...itemsWithoutGroup]
        .sort((a, b) => Math.sign(a.rangeFrom - b.rangeFrom));
    const baseWidth = 12/items.length;
    const groupWidth = (12 - Math.floor(baseWidth)) / (items.length - itemsWithoutGroup.length);

    const renderGroup = (description: ScaleItem, groupIdx: number) => {
        const elements = items.filter((item) => isInGroup(item)(description));
        return (
            <div
                key={description.id}
                className={[
                    style.group,
                    `col-12 col-md-${Math.floor(groupWidth * elements.length)}`,
                    "text-center",
                    "p-3",
                    "d-flex",
                    "flex-column",
                    "justify-content-between",
                ].join(" ")}
            >
                <div className="mb-3">
                    <Content content={description.content} editableFields={editableFields}/>
                </div>
                <div className={`row ${style.blueBorderTop} pt-3`}>
                    {elements.map((element, idx) => renderItem(element, groupIdx, idx, 12/elements.length))}
                </div>
            </div>
        );
    };

    const renderItem = (item: ScaleItem, groupIdx: number, idx: number, width: number = baseWidth, inGroup = true) => {
        const formValue = form && form.watch(code)
        const enableFocus = multi // enable all for multi question
            || (form && !formValue && !defaultValue && !groupIdx && !idx) // focus on first button if is none selected [WCAG]
            || defaultValue === item.value || (formValue && Number(formValue) === item.value)// focus on already selected field [WCAG]

        return (
            <div
                key={item.id}
                className={[
                    `col-12 col-md-${Math.floor(width)}`,
                    "text-center",
                    !inGroup ? "p-3" : "",
                    "d-flex",
                    "justify-content-md-center"
                ].join(" ")}
            >
                <div className="align-self-end d-flex align-items-baseline d-md-block">
                    <SelectQuestionButton
                        value={item.value}
/*                        displayValue={
                            <Content
                                content={item.description as ContentType}
                                editableFields={editableFields}
                            />
                        }*/
                        displayValue={<Content content={item.content}/>}
                        onChange={onChange}
                        defaultChecked={Array.isArray(defaultValue)
                            ? defaultValue.includes(item.value)
                            : defaultValue === item.value
                        }
                        code={code}
                        presentationType="CHILD_STRUCTURED"
                        disabled={!form}
                        multi={multi}
                        tabIndex={form && enableFocus ? 0 : -1}
                        ref={form ? form.register(required ? {required: requiredRule} : {}) : null}
                    >
                         <span className={style.buttonDescription}>
                            <Content
                                content={item.description as ContentType}
                                editableFields={editableFields}
                            />
                        </span>
                    </SelectQuestionButton>
                </div>
            </div>
        );
    };

    return (
        <div style={{ marginBottom: "2rem" }} className={`
            ${ form && form.errors[code] ? style.invalid : ""}
            col-12 mt-4 position-relative
        `}>
            { !ordinalNumber ? null
                : <div className={style.ordinalNumber}>
                    <span dangerouslySetInnerHTML={{__html: ordinalNumber}}/>
                </div>
            }

            <div className={`p-3 ${style.questionContainer}`}>
                <div className="text-center font-weight-bold my-3 my-md-0 pb-2">
                    <Content content={content} editableFields={editableFields}/>
                </div>

                <div className={`row flex-column flex-md-row ${style.blueBorderTop}`}>
                    {
                        roots.map((root, groupIdx) => null == root.value
                            ? renderGroup(root, groupIdx)
                            : renderItem(root, groupIdx, 0, baseWidth, false)
                        )
                    }
                </div>
            </div>

            {
                form
                ? <div className={style.errorWrapper}>
                    <ErrorMessage name={code}/>
                  </div>
                : null
            }
        </div>
    );
};

export default SelectStructuredQuestion;
