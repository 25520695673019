import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Content from "../../../Content/Content";
import { required } from "../../../ErrorMessage/rules";
import LikertScaleButton from "../../LikertScaleButton/LikertScaleButton";
import { getLikertCols, ItemProps } from "../LikertScaleItem";
import style from "./likertScaleTextButtonItem.module.css"

const LikertScaleTextButtonItem: FC<ItemProps> = (
    {
        name,
        scale,
        ordinalNumber,
        defaultValue,
        nthOfType,
        onChange,
        children,
        required: isRequired,
        editableFields
    }
) => {
    const form = useFormContext();

    const buttons = scale.descriptions.map((description, idx) => {
        const formValue = form && form.watch(name)
        const value = `${description.value}`;

        const enableFocus = (form && !form.getValues(name) && !defaultValue && !idx) // focus on first button if is none selected [WCAG]
            || defaultValue === value || (formValue && formValue === value) // focus on already selected field [WCAG]

        const textButton = <span className={ style.description }>
            <Content
                editableFields={editableFields}
                content={{
                    __typename: "TextContent",
                    id: `${(description.description as any).text}`,
                    text: (description.description as any).text,
                    englishText: (description.description as any).englishText,
                    ukrainianText: (description.description as any).ukrainianText,
                    russianText: (description.description as any).russianText,
                }}
            />
        </span>

        return (
            <div
                className="text-md-center col d-md-flex flex-md-column align-items-center my-md-2 mx-1 mb-1"
                key={description.id}
            >
                <LikertScaleButton
                    disabled={!form}
                    defaultChecked={defaultValue === value}
                    value={value}
                    name={name}
                    onChange={onChange}
                    tabIndex={form && enableFocus ? 0: -1}
                    isTextButton
                    ref={form ? form.register(isRequired ? {required} : {}) : null}
                >
                    { textButton }
                </LikertScaleButton>
            </div>
        )
    })

    const even = nthOfType % 2;
    const cols = getLikertCols();

    return (
        <div className={`col-12 py-3 py-md-2 border-bottom ${even ? "bg-light" : "bg-white"} ${style.wrapper}`}>
            <div className="row align-items-baseline">
                <div className={`${cols.question} d-flex ${style.text}`}>
                    <div className="mr-3">
                        <span dangerouslySetInnerHTML={{__html: ordinalNumber}}/>
                    </div>

                    <div>{children}</div>
                </div>
                <div className={`${cols.options} mt-2 mt-md-0 ml-4 ml-md-0 pr-4 px-md-0 align-self-center`}>
                    <div className="row justify-content-center justify-content-md-between no-gutters">
                        {buttons}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LikertScaleTextButtonItem;
