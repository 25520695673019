import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from "apollo-link-error";
import { HttpLink } from 'apollo-link-http';
import clientFetch from "./fetch";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({introspectionQueryResultData});

const httpLink = new HttpLink({
    uri: '/graphql/',
    fetch: clientFetch,
});

const errorLink = onError((error) => {
    if (error.networkError || !error.graphQLErrors) {
        throw error;
    }

    if (1 === error.graphQLErrors.length && !error.graphQLErrors[0].path) {
        throw error;
    }
});

const link = ApolloLink.from([
    errorLink,
    httpLink,
]);

const cache = new InMemoryCache({fragmentMatcher});

const client = new ApolloClient({
    link,
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
            notifyOnNetworkStatusChange: true,
            // @ts-ignore
            partialRefetch: true,
        },
    },
});

export default client;
