import { call, put, takeLeading } from "redux-saga/effects";
import { checkUpdate, updateVersion } from "./versionActions";
import { VersionTypes } from "./versionTypes";

function *versionSaga() {
    yield takeLeading(VersionTypes.CHECK_UPDATE, checkUpdateSaga);
    yield takeLeading(VersionTypes.UPDATE_AVAILABLE, updateAvailableSaga);
    yield takeLeading(VersionTypes.UPDATE, updateSaga);
}

function *checkUpdateSaga({payload}: ReturnType<typeof checkUpdate>) {
    const registration = yield call(getWorkerRegistration);

    if (registration) {
        yield call(updateWorker, registration);
    } else if (payload.force) {
        yield put(updateVersion());
    }
}

function *updateAvailableSaga() {
    yield put(updateVersion());
}

function *updateSaga() {
    const registration = yield call(getWorkerRegistration);

    if (registration && registration.waiting) {
        yield call(skipWaiting, registration.waiting);
    }

    yield call(pageRefresh);
}

const getWorkerRegistration = async () => 'serviceWorker' in navigator
    ? navigator.serviceWorker.getRegistration()
    : undefined;

const pageRefresh = () => document.location.reload();
const updateWorker = (sw: ServiceWorkerRegistration) => sw.update();
const skipWaiting = (sw: ServiceWorker) => sw.postMessage({type: "SKIP_WAITING"});

export default versionSaga;
