import React, { ChangeEventHandler, FC } from "react";
import { useFormContext } from "react-hook-form";
import { required } from "../../../ErrorMessage/rules";
import { ItemProps } from "../LikertScaleItem";
import style from "./likertScaleCompactItem.module.css"

const LikertScaleCompactItem:FC<ItemProps> = (
    {
        scale,
        name,
        onChange,
        defaultValue,
        ordinalNumber,
        nthOfType,
        children,
        required: isRequired,
    }
) => {
    const form = useFormContext();

    const validate = () => form ? form.trigger(name) : undefined;

    const update: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const value = event.currentTarget.value;
        onChange(value);
        validate();
    };

    const options = scale.descriptions.map((description) => (
        <option
            key={description.id}
            value={description.value}
        >
            {(description.content as any).text} - {(description.description as any).text}
        </option>
    ));

    const even = nthOfType % 2;

    return (
        <div className={`p-0 col-12 py-2 border-bottom ${even ? "bg-white" : "bg-light"} ${style.wrapper}`}>
            <div className="row align-items-center">
                <div className="col-1 text-right">
                  <span className="pl-2" dangerouslySetInnerHTML={{__html: ordinalNumber}}/>
                </div>
                <div className="col-2">
                    <select
                        name={name}
                        defaultValue={null == defaultValue ? EMPTY_OPTION_VALUE : defaultValue}
                        onBlur={validate}
                        disabled={!form}
                        onChange={update}
                        className={`${style.select} form-control text-center`}
                        ref={form ? form.register(isRequired ? {required} : {}) : null}
                    >
                        <option value={EMPTY_OPTION_VALUE} disabled />
                        {options}
                    </select>
                </div>

                <div className="col-9">
                    {children}
                </div>
            </div>
        </div>
    );
};

const EMPTY_OPTION_VALUE = "";

export default LikertScaleCompactItem;
