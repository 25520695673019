import React, { forwardRef, ForwardRefRenderFunction, ReactElement, Ref } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux"
import { WcagState } from "../../../store/wcag/wcagReducer";
import { wcagSelector } from "../../../store/wcag/wcagSelectors";
import styles from "./likertScaleButton.module.css";

interface LikertScaleButtonProps {
    name?: string;
    disabled?: boolean;
    active?: boolean;
    loading?: boolean;
    defaultChecked?: boolean;
    miniLegend?: boolean;
    isTextButton?: boolean;
    isPlainText?: boolean;
    isButtonGroup?: boolean;
    tabIndex?: number;
    value: string;
    children?: ReactElement;
    onChange?(value: string): void;
}

const LikertScaleButton: ForwardRefRenderFunction<HTMLInputElement, LikertScaleButtonProps> = (
    {
        name,
        disabled,
        loading,
        value,
        children,
        defaultChecked,
        tabIndex,
        miniLegend,
        onChange,
        isPlainText = false,
        isTextButton = false,
        isButtonGroup = false,
    },
    ref: Ref<HTMLInputElement>
) => {
    const form = useFormContext()
    const highContrast = useSelector(wcagSelector) === WcagState.CONTRAST

    const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      if (form && name) form.trigger(name)

      // if only on real mouse click - pass by on radio input arrow navigation [WCAG]
        if (event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
            (event.target as HTMLInputElement).blur()
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => { // [WCAG]
        if (event.key === 'Enter' && name) {
            event.preventDefault()
            onChange && onChange(value)
            form && form.setValue(name, value)
        }

        if (form && name) form.trigger(name)
    }

    const input = (
        <input
            className={`sr-only ${styles.input} ${miniLegend ? styles.inputMini : ""}`}
            type="radio"
            disabled={disabled || loading}
            name={name}
            value={value}
            defaultChecked={defaultChecked}
            tabIndex={tabIndex}
            onClick={onClick}
            onChange={onChange ? () => onChange(value) : undefined}
            onKeyDown={onKeyDown}
            ref={ref}
        />
    );

    const text = (
        <div
            className={`
                btn
                ${isPlainText ? "" : "btn-outline-dark"}
                ${isPlainText ? styles.plainText : styles.button}
                ${highContrast ? styles.heightContrastButton : ""}
                ${isTextButton ? styles.textButton : ""}
                ${isButtonGroup ? styles.buttonGroup : ""}
                ${disabled || loading ? "disabled" : ""}
                ${miniLegend ? styles.mini : ""}
                ${(children && children.props.content && children.props.content.text && children.props.content.text.length < 2) ? styles.printSmall : ""}
            `}
        >
            { children }
        </div>
    );

    return (
        <label className={`${miniLegend ? styles.labelMini : ""} mb-0`}>
            { input }
            { text }
        </label>
    );
};

export default forwardRef(LikertScaleButton);
