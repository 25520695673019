import React, { FC } from "react";
import BlankLayout from "../../layouts/BlankLayout/BlankLayout";
import style from "./messageLayout.module.css";

export interface MessageLayoutProps {
    title: string;
    subtitle?: string;
    contact?: string;
}

const MessageLayout: FC<MessageLayoutProps> = (props) => {
    const contact = props.contact ? (
        <p>
            W razie pytań prosimy o kontakt:
            <a
                href={`mailto:${props.contact}`}
                title="Otwórz okno nowej wiadomości e-mail"
                className="ml-1"
            >
                {props.contact}
            </a>
        </p>
    ) : null;

    return (
        <BlankLayout title={props.title}>
            <div className={`jumbotron text-center border border-secondary ${style.wrapper}`}>
                <h1 className="mb-5">{props.title}</h1>
                { props.subtitle 
                    ? <h3>{props.subtitle}</h3>
                    : null
                }
                {props.children}
                {contact}
            </div>
        </BlankLayout>
    );
};

export default MessageLayout;
