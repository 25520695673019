import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { call, takeEvery } from "redux-saga/effects";

function* pageSaga() {
    yield takeEvery(LOCATION_CHANGE, locationChangeSaga);
}

function *locationChangeSaga({payload}: LocationChangeAction) {
    if (payload.action !== "POP") {
        yield call(window.scrollTo,0, 0);
    }
}

export default pageSaga;
